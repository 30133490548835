import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { NewsCategory } from '../models/NewsCategory';


class NewsCategoryAPI extends ResourceService<NewsCategory> {

    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.noticiasCategorias,
            'newsCategory',
            NewsCategory
        );
    }
}

export default new NewsCategoryAPI();

import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import styles from './index.module.css'

interface Props {
  colorType: 'Primario' | 'Secundario';
  defaultColor?: string;
  onChange: (color: string) => void;
}

export default function ColorPicker ({ colorType, defaultColor, onChange }: Props) {

  const [paletteVisibility, setPaletteVisibility] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement>(null)
  const [color, setColor] = useState(defaultColor)

  useEffect( () => {
    onChange(defaultColor!)
  //eslint-disable-next-line
  }, [defaultColor])

  useEffect( () => {
    const checkIfClickedOutside = () => {
      if(paletteVisibility && colorPickerRef.current){
        setPaletteVisibility(false);
      }
    }
    document.addEventListener('click', checkIfClickedOutside);
    return() => {
      document.removeEventListener('click', checkIfClickedOutside);
    }
  }, [paletteVisibility])

  const colorChangeComplete = ({ hex: newColor }: ColorResult) => {
    setColor(newColor);
    onChange(newColor);
  }

  return (
    <div className={styles.container}>
      Color {colorType}
      <div className={styles.colorBox} ref={colorPickerRef} onClick={() => setPaletteVisibility(!paletteVisibility)}  style={{backgroundColor: color}}>
      { paletteVisibility && (
        <div>
          <ChromePicker
            color={color}
            onChangeComplete={colorChangeComplete}
          /> 
        </div>
      )}
      </div>
    </div>
  );
};

import React from "react";
import { authRoles } from "../../auth/authRoles";

// const settings = {
//   activeLayout: "layout1",
//   layout1Settings: {
//     topbar: {
//       show: false
//     },
//     leftSidebar: {
//       show: false,
//       mode: "close"
//     }
//   },
//   layout2Settings: {
//     mode: "full",
//     topbar: {
//       show: false
//     },
//     navbar: { show: false }
//   },
//   secondarySidebar: { show: false },
//   footer: { show: false }
// };


const tenantsRoutes = [
  {
    path: "/clientes/nuevo",
    // component: React.lazy(() => import("../../../forms/ClientForm")),
    component: React.lazy(() => import("./TenantForm")),
    auth: authRoles.admin
  },
  {
    path: "/clientes/editar/:id",
    // component: React.lazy(() => import("../../../forms/ClientForm")),
    component: React.lazy(() => import("./TenantForm")),
    auth: authRoles.admin
  },
  {
    path: "/clientes/ver/:id",
    component: React.lazy(() => import("./TenantDetails")),
    auth: authRoles.admin
  },
  {
    path: "/clientes/listado",
    component: React.lazy(() => import("./Tenants")),
    auth: authRoles.admin
  }
];

export default tenantsRoutes;

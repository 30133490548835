import React from "react";
import { authRoles } from "../../auth/authRoles";

const usersRoutes = [
  {
    path: "/usuarios/listado",
    component: React.lazy(() => import("./Users")),
    auth: authRoles.tenant
  },
  {
    path: "/usuarios/editar/:id",
    component: React.lazy(() => import("./UserForm")),
    auth: authRoles.tenant
  },
];

export default usersRoutes;

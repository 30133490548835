import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import usersRoutes from "./views/users/UsersRoutes";
import tenantsRoutes from "./views/tenants/TenantRoutes"
import servicesRoutes from "./views/services/ServiceRoutes"
import prepaidsRoutes from "./views/prepaids/PrepaidRoutes";
import specialistsRoutes from "./views/specialists/SpecialistRoutes"
import specialitiesRoutes from "./views/specialities/SpecialityRoutes"
import promotionsRoutes from "./views/promotions/PromotionRoutes";
import bannerRoutes from "./views/banners/BannerRoutes"
import templatesRoutes from "./views/templates/TemplatesRoutes";
import newsRoutes from "./views/news/NewsRoutes";
import scheduleRoutes from "./views/schedules/ScheduleRoutes";
import newsletterRoutes from "./views/newsletter/NewsletterRoutes";
import clientFormRoutes from "./views/clients/ClientRoutes"

// import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";
// import materialRoutes from "./views/material-kit/MaterialRoutes";
// import dragAndDropRoute from "./views/Drag&Drop/DragAndDropRoute";
// import formsRoutes from "./views/forms/FormsRoutes";
// import mapRoutes from "./views/map/MapRoutes";



const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionRoutes,
  ...dashboardRoutes,
  ...usersRoutes,
  ...tenantsRoutes,
  ...clientFormRoutes,
  ...servicesRoutes,
  ...prepaidsRoutes,
  ...specialistsRoutes,
  ...specialitiesRoutes,
  ...promotionsRoutes,
  ...bannerRoutes,
  ...templatesRoutes,
  ...newsRoutes,
  ...scheduleRoutes,
  ...newsletterRoutes,
  // ...materialRoutes,
  // ...utilitiesRoutes,
  // ...dragAndDropRoute,
  // ...formsRoutes,
  // ...mapRoutes,
  ...redirectRoute,
  ...clientFormRoutes,
  ...errorRoute
];

export default routes;

import React from 'react'
import styles from './ProfileImg.module.css'
import imgToBase64 from '../../utils/imgToBase64'

interface Props {
  file: File,
}

export default function ProfileImg({file}: Props) {

  const [image, setImage] = React.useState<string>()

  React.useEffect( () => {
    if (!file) {
      return
    }else if(typeof(file) === 'string'){
      setImage(file)
    } else {
    imgToBase64(file)
      .then(base64String => setImage(base64String));
    }
  }, [file])

  if(!image) return null;

  return (
    <img src={image} alt='' className={styles.imgTag} />
  )

}
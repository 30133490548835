import { Resource } from '../core/Resource';

export class Prepaid extends Resource {
  public id!: number;
  public name!: string;
  public type!: string;

  // constructor() {
  //   super();
  // }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const prepaid = Object.assign(this as any, {});
    return prepaid;
  }

}

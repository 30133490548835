//Libraries + Modules + Hooks
import React from 'react'
import styles from './index.module.css';
// import { connect } from 'react-redux'

//Components
import SectionTitle from '../../components/Section/SectionTitle';
import SectionSubtitle from '../../components/Section/SectionSubtitle';
import TemplateList from '../../components/TemplateList';
import WebColors from '../../components/WebColors';
import StepComponentWrapper from '../../components/StepComponentWrapper';

 interface Props {
   logo: File,
   primaryColorSelected: (color: string) => void,
   secondaryColorSelected: (color: string) => void,
   primaryColor: string,
   secondaryColor: string,
   templateSelected: (id: number, templateSelectedPreview: string) => void,
   templateOptions: Array<{id: number, preview: string, thumbnail: string}>,
   activeOption: number,
 }

export default function YourWeb ({primaryColorSelected, secondaryColorSelected, primaryColor, secondaryColor,templateOptions, activeOption, templateSelected, logo}: Props) {

  return(
      <>
        <div>
          <SectionTitle
            title= 'Tu web'
          />
        </div>
        <div className={styles.sectionSubtitle}>
          <SectionSubtitle
            subtitle= 'Paleta de colores'
          />
        </div>
        <div>
          <StepComponentWrapper
            title='Sus colores'
            stepList= {['Seleccione un color primario para utilizar en su pagina web', 'Seleccione un color secundario para utilizar en su pagina web']}
            activeStep={0}
          >
            <WebColors
            logo={logo}
            primaryColor={color => primaryColorSelected(color)}
            secondaryColor={color => secondaryColorSelected(color)}
            defaultPrimaryColor={primaryColor}
            defaultSecondaryColor={secondaryColor}
          />
          </StepComponentWrapper>
        </div>
        <div>
          <SectionSubtitle
            subtitle= 'Elegi tu template'
          />
        </div>
        <div>
          <StepComponentWrapper
            title='Su Template'
            stepList= {['Seleccione el template que desee para su pagina web']}
            activeStep={0}
          >
            <TemplateList
              activeOption={activeOption}
              options={templateOptions}
              onTemplateChange={(id, templateSelectedPreview) => templateSelected(id, templateSelectedPreview)}
            />
          </StepComponentWrapper>
        </div>
      </>
  )
}

//  const mapStateToProps = (state: any) => ({
//    activeOption: state.activeOption,
//    templateOption: state.templateOption
//  });

import jwtAuthService from "../../services/jwtAuthService";
import firebaseAuthService from "../../services/firebase/firebaseAuthService";
import { setUserData } from "./UserActions";
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

const ERRORS = {
  "auth/email-already-in-use": "El email ya se encuentra en uso",
  "auth/user-not-found": "El usuario no se encuentra registrado",
}

function getErrorMessage(errorCode) {
  let message = ERRORS[errorCode];
  if (!message) {
    message = "Ha ocurrido un error inesperado"
  }
  return message;
}

export function loginWithEmailAndPassword({ email, password }) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    jwtAuthService
      .loginWithEmailAndPassword(email, password)
      .then(user => {
        dispatch(setUserData({
          ...user
        }));

        history.push({
          pathname: "/"
        });

        return dispatch({
          type: LOGIN_SUCCESS
        });
      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD
    });
  };
}

export function firebaseLoginEmailPassword({ email, password }) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    firebaseAuthService.signInWithEmailAndPassword(email, password)
      .then(async user => {
        if (user) {
          dispatch(setUserData({
            ...user
          }));
          history.push({
            pathname: "/"
          });
          return dispatch({
            type: LOGIN_SUCCESS
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed"
          });
        }
      })
      .catch(error => {
        snackbarService.showSnackbar(
          getErrorMessage(error.code),
          "error",
          500
        );
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}

export function firebaseLoginWithPopup(media) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    firebaseAuthService.signInWithPopup(media)
      .then(async user => {
        if (user) {
          dispatch(setUserData({
            ...user
          }));
          history.push({
            pathname: "/"
          });

          return dispatch({
            type: LOGIN_SUCCESS
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed"
          });
        }
      })
      .catch(error => {
        snackbarService.showSnackbar(
          getErrorMessage(error.code),
          "error",
          500
        );
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}

export function firebaseSignUp({ email, password, agreement }) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    firebaseAuthService.signUpWithEmailAndPassword(email, password)
      .then(async user => {
        if (user) {
          dispatch(setUserData({
            ...user
          }));
         history.push({
           pathname: "/"
         });

          return dispatch({
            type: LOGIN_SUCCESS
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Signup Failed"
          });
        }
      })
      .catch(error => {
        snackbarService.showSnackbar(
          getErrorMessage(error.code),
          "error",
          500
        );
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}
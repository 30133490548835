import {
  FETCH_TENANT_DATA,
  FETCH_TENANT_DATA_FULFILLED,
  DELETE_TENANT_FULFILLED,
  TENANT_ERROR,
  CREATE_TENANT,
  CREATE_TENANT_FULFILLED,
  UPDATE_TENANT,
  UPDATE_TENANT_FULFILLED
} from "../actions/TenantActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
};

const tenantReducer = function (state = initialState, action) {
  switch (action.type) {
    case TENANT_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case CREATE_TENANT: {
      return {
        ...state,
        saving: true,
      };
    }
    case CREATE_TENANT_FULFILLED: {
      let new_data;
      if (state.data) {
        new_data = [...state.data.filter((v) => v.id !== action.id), action.data]
      } else {
        new_data = [action.data]
      }
      return {
        ...state,
        saving: false,
        data: new_data,
      };
    }
    case UPDATE_TENANT: {
      return {
        ...state,
        saving: true,
      };
    }
    case UPDATE_TENANT_FULFILLED: {
      if (state.data) {
        return {
          ...state,
          fetching: false,
          fetched: true,
          saving: false,
          data: [...state.data.map((el) => el.id === action.data.id ? action.data : el)],
        };
      } else {
        return {
          ...state,
          fetching: false,
          fetched: false,
          saving: false,
        };
      }
    }
    case FETCH_TENANT_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_TENANT_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case DELETE_TENANT_FULFILLED: {
      return {
        ...state,
        data: state.data.filter((row) => row.id !== action.data),
      };
    }
    default: {
      return state;
    }
  }
};

export default tenantReducer;

import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import { ActionsMenu } from "../../../shared";

import { formatDate } from "../../../../utils";

const MAX_LONG_TEXT_LENGTH = 100;

export const COLUMN_TYPES = {
  IMAGE: 'IMG',
  SHORT_TEXT: 'STRING',
  LONG_TEXT: 'TEXT',
  DATE:'DATE',
  LINK:'LINK',
};

const PaginationTable = (props) => {
  const { data, config } = props;
  const { columns, showActions, showPagination } = config;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [inputData, setInputData] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
  };



  //Input Handler
  const onChangeHandler = (e) => setInputData(e.target.value);

  //Filter data
  // const filteredData = data.filter(cols =>  
  //   cols.role.name.toLowerCase().includes(inputData.toLowerCase()) ||
  //   cols.email.toLowerCase().includes(inputData.toLowerCase()) ||
  //   cols.last_login.toLowerCase().includes(inputData.toLowerCase())  );
  

  let dataToShow = showPagination ? Array.isArray(data) && data
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : 
  data;

  const renderRow = (key=null, columnType = COLUMN_TYPES.SHORT_TEXT, field, subfield = "", link = "", data) => {
    switch (columnType) {
      case COLUMN_TYPES.IMAGE:
        return (<TableCell key={key} className="px-0 capitalize" align="left">
          <a href={data[link]} target="_blank" rel="noopener noreferrer">
            <img alt={data[data.alt]} src={data[field]} width="90" />
          </a>
        </TableCell>);
      case COLUMN_TYPES.LONG_TEXT:
        return (
          data[field].length > MAX_LONG_TEXT_LENGTH ?
            <TableCell className="px-0 capitalize" align="left">{data[field].substr(0, MAX_LONG_TEXT_LENGTH - 3) + '...'}</TableCell> :
            <TableCell key={key} className="px-0 capitalize" align="left">{data[field]}</TableCell>
        );
      case COLUMN_TYPES.DATE:
        return (
          <TableCell key={key} className="px-0 capitalize" align="left">{formatDate(data[field], 'es', 'LLL')}</TableCell>
        );
      case COLUMN_TYPES.LINK:
        return (
          <TableCell key={key} className="px-0 capitalize" align="left">
            <a href={data[field]} target="_blank" rel="noopener noreferrer">
              {data[field]}
            </a>
          </TableCell>
        );
      default:
        return (<TableCell key={key} className="px-0 capitalize" align="left">{subfield ? data[field][subfield] : data[field]}</TableCell>);
    }
  }

  const renderActions = (actions, id) => {
    return (
      (actions && actions.length) ?
        (
          <TableCell className="px-0">
            <ActionsMenu
              actions={actions}
              id={id}
            />
          </TableCell>
        ) : ''
    )
  }

  const renderPagination = () => {
    if (showPagination) {
      return (
        <TablePagination
          // key={index}
          className="px-4"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page} 
          backIconButtonProps={{
            "aria-label": "Página Previa"
          }}
          nextIconButtonProps={{
            "aria-label": "Siguiente Página"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )
    }
    return null
  }

  return (
    <div className="w-full overflow-auto">
      <TextField
        value={inputData}
        id="input-with-icon-textfield"
        label="Search"
        onChange={onChangeHandler}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: inputData ? 'visible' : 'hidden' }}
              onClick={() => setInputData('')}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        variant="standard"
      />
      <Table className="whitespace-pre">
        <TableHead>
          <TableRow>
            {columns
              .map((col, index) => (
                <TableCell key={index} className="px-0">{col.header}</TableCell>
              ))}
            {showActions.enabled ? <TableCell className="px-0">{showActions.header}</TableCell> : ''}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToShow
            .map((row, index) => (
              <TableRow key={index}>
                {columns
                  .map((col, index2) => (
                    renderRow(index2, col.type, col.field, col.subfield, col.link, row)
                  ))}
                {showActions.enabled ? renderActions(showActions.actions, row[config.keyfield]) : ''}
              </TableRow>
            ))}
        </TableBody>
      </Table>

      {renderPagination()}

    </div>
  );
};

export default PaginationTable;

import { Resource } from '../core/Resource';
import { Pharmacy } from "./Pharmacy";

export class Schedule extends Resource {
  public id!: number;
  public day!: string;
  public hours!: string;
  public pharmacy?: Pharmacy | number;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const schedule = Object.assign(this as any, {});
    return schedule;
  }

}

import React from 'react';
import styles from './index.module.css'

interface Props {
  onClick?: () => void;
};

export default function GoBack ({ onClick }: Props) {
  return (
    <div className={styles.container} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#B6BCCD"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/></svg>
      <span className={styles.span}> 
        volver atrás
      </span>
    </div>
  );
};

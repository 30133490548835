import React from 'react';
import Button from '../../Button';
import styles from './index.module.css'

interface Props {
  isSelected: boolean;
  imageUrl: string;
  onClick: () => void;
  onTemplate: () => void
}

export default function TemplateOption ({ isSelected, imageUrl, onClick, onTemplate }: Props) {

  let optionSelectedStyle = `${styles.optionBox} `;
  let optionSelectedContainer = `${styles.optionContainer} `;
  if (isSelected) {
    optionSelectedStyle += styles.optionSelected
    optionSelectedContainer += styles.optionSelectedContainer
  };

  return (
    <div className={optionSelectedContainer} onClick={onTemplate}>
      <div className={styles.optionImage} style={{backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover'}}>
        <div className={optionSelectedStyle}><svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 22 22" width="18px" fill='#ffffff'><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
        </div> 
      </div>
      <div className={styles.optionButton} >
          <Button children='ver' secondary={false} size='verySmall' onClick={onClick}/>
      </div>
    </div>

  );
};

import {
  FETCH_PHARMACY_DATA,
  FETCH_PHARMACY_DATA_FULFILLED
} from "../actions/PharmacyActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const PharmacyReducer = function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PHARMACY_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_PHARMACY_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    default: {
      return state;
    }
  }
};

export default PharmacyReducer;

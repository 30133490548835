import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { Service } from '../models/Service';


class ServiceAPI extends ResourceService<Service> {

    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.servicios,
            'service',
            Service
        );
    }

//     /**
//    * Obtener un listado de elementos
//    * @param params - Parámetros de búsqueda
//    */
//     public buildSite(id: number, params: {} = {}): AxiosObservable<Service> {
//         return Axios
//             .post(`${this.url}/buildsite/${id}`, { params }).pipe(
//                 retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
//                 timeout(10000),
//                 catchError(errorHandler.handleError),
//                 map((res: any) => {
//                     // res.data = this.convertData(res.data);
//                     return res;
//                 })
//             );
//     }
}

export default new ServiceAPI();

import { Speciality } from "../../models/Speciality"
import SpecialityAPI from "../../apis/Specialities"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

export const SPECIALITY_ERROR = "SPECIALITY_ERROR"
export const CREATE_SPECIALITY = "CREATE_SPECIALITY";
export const CREATE_SPECIALITY_FULFILLED = "CREATE_SPECIALITY_FULFILLED";
export const FETCH_SPECIALITY_DATA = "SPECIALITY_FETCH_DATA";
export const FETCH_SPECIALITY_DATA_FULFILLED = "SPECIALITY_FETCH_DATA_FULFILLED";
export const DELETE_SPECIALITY = "DELETE_SPECIALITY"
export const DELETE_SPECIALITY_FULFILLED = "DELETE_SPECIALITY_FULFILLED"

//  Action creator
export const createSpeciality = ({name, description}) => dispatch => {
  dispatch({
    type: CREATE_SPECIALITY,
    payload: {name, description}
  });
}

//  Action creator
export function createSpecialityFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_SPECIALITY_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createSpecialityEpic = action$ => action$.pipe(
  ofType(CREATE_SPECIALITY),
  mergeMap(action => {
    const speciality = new Speciality().deserialize(action.payload);
    return SpecialityAPI.create(speciality)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Especialidad ${response.data.name} creada con éxito`, 
            'success',
            500
          );
          history.push({
            pathname: "/especialidades/listado"
          });
          return createSpecialityFulfilled(response.data);
        }),
        catchError(error => of({type: SPECIALITY_ERROR}))
      )
    }
  )
);

//  Action creator
export const fetchSpecialities = () => dispatch => {
  dispatch({
    type: FETCH_SPECIALITY_DATA,
  });
}

//  Action creator
export function fetchSpecialitiesFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_SPECIALITY_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchSpecialitiesEpic = action$ => action$.pipe(
  ofType(FETCH_SPECIALITY_DATA),
  mergeMap(action =>
    SpecialityAPI.getAll()
      .pipe(
        map(response => fetchSpecialitiesFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteSpeciality = (id) => dispatch => {
  dispatch({
    type: DELETE_SPECIALITY,
    payload: id
  });
}

//  Action creator
export function deleteSpecialityFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_SPECIALITY_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteSpecialityEpic = action$ => action$.pipe(
  ofType(DELETE_SPECIALITY),
  mergeMap(action =>
    SpecialityAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito la especialidad ' + action.payload.name,
            'success',
            500
          );
          return deleteSpecialityFulfilled(action.payload.id);
        })
      )
  )
);

export const specialityEpics = [
  createSpecialityEpic,
  fetchSpecialitiesEpic,
  deleteSpecialityEpic
]


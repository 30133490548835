import { News } from "../../models/News"
import NewsAPI from "../../apis/News"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { of } from "rxjs";

export const NEWS_ERROR = "NEWS_ERROR"
export const CREATE_NEWS = "CREATE_NEWS";
export const CREATE_NEWS_FULFILLED = "CREATE_NEWS_FULFILLED";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const UPDATE_NEWS_FULFILLED = "UPDATE_NEWS_FULFILLED";
export const FETCH_NEWS_DATA = "NEWS_FETCH_DATA";
export const FETCH_NEWS_DATA_FULFILLED = "NEWS_FETCH_DATA_FULFILLED";
export const DELETE_NEWS = "DELETE_NEWS"
export const DELETE_NEWS_FULFILLED = "DELETE_NEWS_FULFILLED"

//  Action creator
export const createNews = ({ id, date_published, title, headline, content, image, category, province_name, city_name, tags_list }) => dispatch => {
  dispatch({
    type: CREATE_NEWS,
    payload: { id, date_published, title, headline, content, image, category, province_name, city_name, tags_list }
  });
}

//  Action creator
export function createNewsFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_NEWS_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createNewsEpic = action$ => action$.pipe(
  ofType(CREATE_NEWS),
  mergeMap(action => {
    const news = new News().deserialize(action.payload);
    return NewsAPI.create(news)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Noticia ${response.data.name} creada con éxito`,
            'success',
            500
          );
          history.push({
            pathname: "/novedades/listado"
          });
          return createNewsFulfilled(response.data);
        }),
        catchError(error => of({ type: NEWS_ERROR }))
      )
  }
  )
);

//  Action creator
export const updateNews =
  ({ ...fields }) =>
    (dispatch) => {
      dispatch({
        type: UPDATE_NEWS,
        payload: { ...fields },
      });
    };

//  Action creator
export function updateNewsFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NEWS_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateNewsEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_NEWS),
    mergeMap((action) => {
      const news = new News().deserialize(action.payload);
      return NewsAPI.update(news).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Noticia ${news.title} editada con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/novedades/listado",
          });
          return updateNewsFulfilled(response.data);
        })
      );
    })
  );

//  Action creator
export const fetchNews = () => dispatch => {
  dispatch({
    type: FETCH_NEWS_DATA,
  });
}

//  Action creator
export function fetchNewsFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_NEWS_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchNewsEpic = action$ => action$.pipe(
  ofType(FETCH_NEWS_DATA),
  mergeMap(action =>
    NewsAPI.getAll()
      .pipe(
        map(response => fetchNewsFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteNews = (id) => dispatch => {
  dispatch({
    type: DELETE_NEWS,
    payload: id
  });
}

//  Action creator
export function deleteNewsFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_NEWS_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteNewsEpic = action$ => action$.pipe(
  ofType(DELETE_NEWS),
  mergeMap(action =>
    NewsAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito la noticia ' + action.payload.name,
            'success',
            500
          );
          return deleteNewsFulfilled(action.payload.id);
        })
      )
  )
);

export const newsEpics = [
  createNewsEpic,
  updateNewsEpic,
  fetchNewsEpic,
  deleteNewsEpic
]

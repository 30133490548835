import { Template } from "../../models/Template"
import TemplateAPI from "../../apis/Templates"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

export const TEMPLATE_ERROR = "TEMPLATE_ERROR"
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const CREATE_TEMPLATE_FULFILLED = "CREATE_TEMPLATE_FULFILLED";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const UPDATE_TEMPLATE_FULFILLED = "UPDATE_TEMPLATE_FULFILLED";
export const FETCH_TEMPLATE_DATA = "TEMPLATE_FETCH_DATA";
export const FETCH_TEMPLATE_DATA_FULFILLED = "TEMPLATE_FETCH_DATA_FULFILLED";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE"
export const DELETE_TEMPLATE_FULFILLED = "DELETE_TEMPLATE_FULFILLED"

//  Action creator
export const createTemplate = ({name, repository, description, live_preview, thumbnail}) => dispatch => {
  dispatch({
    type: CREATE_TEMPLATE,
    payload: {name, repository, description, live_preview, thumbnail}
  });
}

//  Action creator
export function createTemplateFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_TEMPLATE_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createTemplateEpic = action$ => action$.pipe(
  ofType(CREATE_TEMPLATE),
  mergeMap(action => {
    const template = new Template().deserialize(action.payload);
    return TemplateAPI.create(template)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Template ${response.data.name} creado con éxito`, 
            'success',
            500
          );
          history.push({
            pathname: "/templates"
          });
          return createTemplateFulfilled(response.data);
        }),
        catchError(error => of({type: TEMPLATE_ERROR}))
      )
    }
  )
);

//  Action creator
export const updateTemplate =
  ({ ...fields }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: { ...fields },
    });
  };

//  Action creator
export function updateTemplateFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TEMPLATE_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateTemplateEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_TEMPLATE),
    mergeMap((action) => {
      const template = new Template().deserialize(action.payload);
      return TemplateAPI.update(template).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Template ${template.name} editado con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/templates/listado",
          });
          return updateTemplateFulfilled(response.data);
        })
      );
    })
  );

//  Action creator
export const fetchTemplates = () => dispatch => {
  dispatch({
    type: FETCH_TEMPLATE_DATA,
  });
}

//  Action creator
export function fetchTemplatesFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_TEMPLATE_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchTemplatesEpic = action$ => action$.pipe(
  ofType(FETCH_TEMPLATE_DATA),
  mergeMap(action =>
    TemplateAPI.getAll()
      .pipe(
        map(response => fetchTemplatesFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteTemplate = (id) => dispatch => {
  dispatch({
    type: DELETE_TEMPLATE,
    payload: id
  });
}

//  Action creator
export function deleteTemplateFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_TEMPLATE_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteTemplateEpic = action$ => action$.pipe(
  ofType(DELETE_TEMPLATE),
  mergeMap(action =>
    TemplateAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito el template ' + action.payload.name,
            'success',
            500
          );
          return deleteTemplateFulfilled(action.payload.id);
        })
      )
  )
);

export const templateEpics = [
  createTemplateEpic,
  updateTemplateEpic,
  fetchTemplatesEpic,
  deleteTemplateEpic
];

import { combineReducers } from "redux";
import { combineEpics } from 'redux-observable';


// Core reducers
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import UsersReducer from "./UsersReducer";
import TenantReducer from "./TenantReducer";
import RoleReducer from "./RoleReducer";
import { tenantEpics } from "../actions/TenantActions";
import { userEpics } from "../actions/UserActions";
import { usersEpics } from "../actions/UsersActions";
import { roleEpics } from "../actions/RoleActions";
import { serviceEpics } from "../actions/ServiceActions";
import { prepaidEpics } from "../actions/PrepaidActions";
import { specialistEpics } from "../actions/SpecialistActions";
import { specialityEpics } from "../actions/SpecialityActions";
import { promotionEpics } from "../actions/PromotionActions";
import { bannerEpics } from "../actions/BannerActions";
import { templateEpics } from "../actions/TemplateActions";
import { pharmacyEpics } from "../actions/PharmacyActions";
import { newsEpics } from "../actions/NewsActions";
import { newsCategoryEpics } from "../actions/NewsCategoryActions";
import { newsTagEpics } from "../actions/NewsTagActions";
import { scheduleEpics } from "../actions/ScheduleActions";
import { newsletterEpics } from "../actions/NewsletterActions";
import { notificationTypeEpics } from "../actions/NotificationTypeActions";
import { notificationEpics } from "../actions/NotificationActions";

// Template Reducers
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import EcommerceReducer from "./EcommerceReducer";
import NavigationReducer from "./NavigationReducer";

import { catchError } from 'rxjs/operators';
import ServiceReducer from "./ServiceReducer";
import PrepaidReducer from "./PrepaidReducer";
import SpecialistReducer from "./SpecialityReducer";
import SpecialityReducer from "./SpecialityReducer";
import PromotionReducer from "./PromotionReducer";
import BannerReducer from "./BannerReducer";
import TemplateReducer from "./TemplateReducer";
import PharmacyReducer from "./PharmacyReducer";
import NewsReducer from "./NewsReducer";
import NewsCategoryReducer from "./NewsCategoryReducer";
import NewsTagReducer from "./NewsTagReducer";
import ScheduleReducer from "./ScheduleReducer";
import NewsletterReducer from "./NewsletterReducer";
import NotificationTypeReducer from "./NotificationTypeReducer";
import NotificationReducer from "./NotificationReducer";

const epics = [
  ...tenantEpics,
  ...userEpics,
  ...usersEpics,
  ...roleEpics,
  ...serviceEpics,
  ...prepaidEpics,
  ...specialistEpics,
  ...specialityEpics,
  ...promotionEpics,
  ...bannerEpics,
  ...templateEpics,
  ...pharmacyEpics,
  ...newsEpics,
  ...newsCategoryEpics,
  ...newsTagEpics,
  ...scheduleEpics,
  ...newsletterEpics,
  ...notificationTypeEpics,
  ...notificationEpics,
]

export const rootEpic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  users: UsersReducer,
  roles: RoleReducer,
  newsletter: NewsletterReducer,
  tenants: TenantReducer,
  services: ServiceReducer,
  prepaids: PrepaidReducer,
  specialists: SpecialistReducer,
  specialities: SpecialityReducer,
  promotions: PromotionReducer,
  banners: BannerReducer,
  templates: TemplateReducer,
  pharmacies: PharmacyReducer,
  news: NewsReducer,
  newsCategory: NewsCategoryReducer,
  newsTag: NewsTagReducer,
  schedules: ScheduleReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notificationType: NotificationTypeReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  navigations: NavigationReducer
});

export default RootReducer;

import React from "react";
import { authRoles } from "../../auth/authRoles";

const specialitiesRoutes = [
  {
    path: "/especialidades/nuevo",
    component: React.lazy(() => import("./SpecialityForm")),
    auth: authRoles.admin
  }, {
    path: "/especialidades/listado",
    component: React.lazy(() => import("./Specialities")),
    auth: authRoles.admin
  }
];

export default specialitiesRoutes;

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import { environment } from "../../environments/environment"
import AuthUtils from "../authUtils"
import localStorageService from "../localStorageService";

class FirebaseAuthService {
  auth;
  // firestore;
  //   database;
  //   storage;

  googleProvider;
  facebookProvider;
  twitterProvider;

  // // UNCOMMENT IF YOU WANT TO USE FIREBASE
  constructor() {
    this.init();
    this.auth = firebase.auth();
    // this.firestore = firebase.firestore();

    // this.database = firebase.database();
    // this.storage = firebase.storage();

    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();
  }

  init = () => {
    firebase.initializeApp(environment.firebase);
  };

  checkAuthStatus = callback => {
    this.auth.onAuthStateChanged(callback);
  };

  signUpWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        return this.loginWithToken(userCredentials)
          .then((user) => {
            return user;
          })
      });
  };

  signUpWithEmailAndPasswordRoleTenant = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        const extraParams = {role: "TENANT"};
        return this.loginWithToken(userCredentials, extraParams)
          .then((user) => {
            return user;
          })
      });
  };

  signInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        return this.loginWithToken(userCredentials)
          .then((user) => {
            return user;
          })
      });
  };

  getProvider(media) {
    switch (media) {
      case "google":
        return this.googleProvider;

      case "facebook":
        return this.facebookProvider;

      case "twitter":
        return this.twitterProvider;

      default:
        break;

    }
  }

  signInWithPopup = media => {
    return this.auth.signInWithPopup(this.getProvider(media))
      .then((userCredentials) => {
        return this.loginWithToken(userCredentials)
          .then((user) => {
            return user;
          })
      });
  };

  signInWithPhoneNumber = phoneNumber => {
    return this.auth.signInWithPhoneNumber(phoneNumber);
  };

  // getUserData = docId => {
  //   //   generally it's better to use uid for docId
  //   this.firestore
  //     .collection("users")
  //     .doc(docId)
  //     .get()
  //     .then(doc => {
  //       console.log(doc.data());
  //     });
  // };

  // getAllUser = () => {
  //   this.firestore
  //     .collection("users")
  //     .get()
  //     .then(docList => {
  //       docList.forEach(doc => {
  //         console.log(doc.data());
  //       });
  //     });
  // };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = (userCredential, extraParams={}) => {
    return AuthUtils.sendAuthDataToServer(userCredential.user, extraParams)
      .then(async data => {
        const idToken = await userCredential.user.getIdToken();
        userCredential.token = idToken;//userCredential.credential.accessToken;
        userCredential.userId = data.id;
        userCredential.role = data.role.name;

        const allData = Object.assign(userCredential, localStorageService.getItem("auth_user"));

        AuthUtils.setSession(idToken);
        AuthUtils.setUser(userCredential);
        // AuthUtils.setUser(allData);
        return allData;
      })//.catch(error => console.log("::::::error"));
  };

  reauthenticate = (user) => {
    if (!user) return null;
    AuthUtils.sendAuthDataToServer(user)
    .then(async data => {
        const idToken = await user.getIdToken();
        AuthUtils.setSession(idToken);
    })
  }
  
  signOut = () => {
    AuthUtils.logout();
    return this.auth.signOut();
  };
}

const instance = new FirebaseAuthService();

export default instance;

import {
  FETCH_PREPAID_DATA,
  FETCH_PREPAID_DATA_FULFILLED,
  DELETE_PREPAID_FULFILLED,
  PREPAID_ERROR,
  CREATE_PREPAID,
  CREATE_PREPAID_FULFILLED,
  UPDATE_PREPAID,
  UPDATE_PREPAID_FULFILLED
} from "../actions/PrepaidActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const PrepaidReducer = function (state = initialState, action) {
  switch (action.type) {
    case PREPAID_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case CREATE_PREPAID: {
      return {
        ...state,
        saving: true,
      };
    }
    case CREATE_PREPAID_FULFILLED: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.data],
      };
    }
    case UPDATE_PREPAID: {
      return {
        ...state,
        saving: true,
      };
    }
    case UPDATE_PREPAID_FULFILLED: {
      return {
        fetching: false,
        fetched: true,
        saving: false,
        data: [...state.data.map((el) => el.id === action.data.id ? action.data : el)],
      };
    }
    case FETCH_PREPAID_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_PREPAID_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case DELETE_PREPAID_FULFILLED: {
      return {
        ...state,
        data: state.data.filter((row) => row.id !== action.data),
      };
    }
    default: {
      return state;
    }
  }
};

export default PrepaidReducer;

import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { Speciality } from '../models/Speciality';


class SpecialityAPI extends ResourceService<Speciality> {
    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.especialidades,
            'speciality',
            Speciality
        );
    }
}

export default new SpecialityAPI();

import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from 'redux-observable';
import RootReducer, { rootEpic } from "./reducers/RootReducer";

const epicMiddleware = createEpicMiddleware();

const initialState = {};
const middlewares = [thunk, epicMiddleware];
let devtools = x => x;

if (
  process.env.NODE_ENV !== "production" &&
  process.browser &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({trace: true});
}

// export const Store = createStore(
//   RootReducer,
//   initialState,
//   compose(applyMiddleware(...middlewares), devtools)
// );

export function configureStore() {
  const Store = createStore(
    RootReducer,
    initialState,
    compose(applyMiddleware(...middlewares), devtools)
  );

  epicMiddleware.run(rootEpic);

  return Store;
}


import { ResourceService } from "../core/ResourceService";
import { environment } from "../environments/environment";
import { Pharmacy } from "../models/Pharmacy";

class PharmacyAPI extends ResourceService<Pharmacy> {
  constructor() {
    super(environment.api.base, environment.api.farmacias, "pharmacy", Pharmacy);
  }
}

export default new PharmacyAPI();

import { Banner } from "../../models/Banner"
import BannerAPI from "../../apis/Banners"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

export const BANNER_ERROR = "BANNER_ERROR"
export const CREATE_BANNER = "CREATE_BANNER";
export const CREATE_BANNER_FULFILLED = "CREATE_BANNER_FULFILLED";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const UPDATE_BANNER_FULFILLED = "UPDATE_BANNER_FULFILLED";
export const FETCH_BANNER_DATA = "BANNER_FETCH_DATA";
export const FETCH_BANNER_DATA_FULFILLED = "BANNER_FETCH_DATA_FULFILLED";
export const DELETE_BANNER = "DELETE_BANNER"
export const DELETE_BANNER_FULFILLED = "DELETE_BANNER_FULFILLED"

//  Action creator
export const createBanner = ({id, name, title, paragraph, link, image, image_mobile, use_cta}) => dispatch => {
  dispatch({
    type: CREATE_BANNER,
    payload: {id, name, title, paragraph, link, image, image_mobile, use_cta}
  });
}

//  Action creator
export function createBannerFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_BANNER_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createBannerEpic = action$ => action$.pipe(
  ofType(CREATE_BANNER),
  mergeMap(action => {
    const banner = new Banner().deserialize(action.payload);
    return BannerAPI.create(banner)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Banner ${banner.name} creado con éxito`, 
            'success',
            500
          );
          history.push({
            pathname: "/banners/listado"
          });
          return createBannerFulfilled(response.data);
        }),
        catchError(error => of({type: BANNER_ERROR}))
      )
    }
  )
);

//  Action creator
export const updateBanner =
  ({ ...fields }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_BANNER,
      payload: { ...fields },
    });
  };

//  Action creator
export function updateBannerFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BANNER_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateBannerEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_BANNER),
    mergeMap((action) => {
      const banner = new Banner().deserialize(action.payload);
      return BannerAPI.update(banner).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Banner ${banner.name} editado con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/banners/listado",
          });
          return updateBannerFulfilled(response.data);
        })
      );
    })
  );


//  Action creator
export const fetchBanners = () => dispatch => {
  dispatch({
    type: FETCH_BANNER_DATA,
  });
}

//  Action creator
export function fetchBannersFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_BANNER_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchBannersEpic = action$ => action$.pipe(
  ofType(FETCH_BANNER_DATA),
  mergeMap(action =>
    BannerAPI.getAll()
      .pipe(
        map(response => fetchBannersFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteBanner = (id) => dispatch => {
  dispatch({
    type: DELETE_BANNER,
    payload: id
  });
}

//  Action creator
export function deleteBannerFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_BANNER_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteBannerEpic = action$ => action$.pipe(
  ofType(DELETE_BANNER),
  mergeMap(action =>
    BannerAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito el banner ' + action.payload.name,
            'success',
            500
          );
          return deleteBannerFulfilled(action.payload.id);
        })
      )
  )
);

export const bannerEpics = [
  createBannerEpic,
  updateBannerEpic,
  fetchBannersEpic,
  deleteBannerEpic
]


import React, { useEffect, useState } from 'react';
import styles from './index.module.css';

interface Props {
  required: boolean;
  label: string;
  placeholder: string;
  onChange: (value: string) => void;
  value: string | undefined,
  errorMessage: string,
  validator: (value: string) => void,
};

export default function Input ({ validator, required, label, placeholder, onChange, value= '', errorMessage}: Props) {

  const[isValid, setIsValid] = useState<any>(true)

  useEffect(() => {
    if (value) {
      const isValid = validator(value);
      setIsValid(isValid!);
    }
  }, [value, validator]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const isValid = validator(value)
    setIsValid(isValid!);
    onChange(value);
  };

  return (
    <div className={styles.container}>
      <label className={styles.label}> { required ? label + '*': label } </label>
      <input 
        className={styles.input} 
        onChange={onChangeHandler} 
        placeholder={placeholder}
        value={value}
      />
      {!isValid && <span className={styles.errorMessage}>{errorMessage}</span>}
    </div>
  );
};

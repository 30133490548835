import { Resource } from '../core/Resource';
import { Tenant } from "./Tenant";

export class Newsletter extends Resource {
  public id!: number;
  public created_at?: string;
  public name!: string;
  public email!: string;
  public source!: string;
  public tenant!: Tenant | number;

  // constructor() {
  //   super();
  // }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const newsletter = Object.assign(this as any, {});
    return newsletter;
  }

}

import history from "../../../history.js";
// import jwtAuthService from "../../services/jwtAuthService";
import firebaseAuthService from "../../services/firebase/firebaseAuthService"
import UserAPI from "../../apis/Users"

import { snackbarService } from "uno-material-ui";
import { map, mergeMap } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';

import localStorageService from "../../services/localStorageService";

export const VERIFY_ID_TOKEN = "VERIFY_ID_TOKEN"
export const VERIFY_ID_TOKEN_FULFILLED = "VERIFY_ID_TOKEN_FULFILLED"

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

const getStorageUserData = (user) => {
  const prevData = localStorageService.getItem("auth_user");

  if (!!user && !!prevData && prevData.uid === user.uid) {
    const userData = {
      ...prevData.user,
      token: prevData.token,
      userId: prevData.userId,
      role: prevData.role,
    };
    return userData;
  }
  return user;
};

export function setUserData(user) {
  const data = getStorageUserData(user);
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: data
    });
  };
}

export function logoutUser() {
  return dispatch => {
    // jwtAuthService.logout();
    firebaseAuthService.signOut()

    history.push({
      pathname: "/session/signin"
    });

    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}

//  Action creator
export const getByUID = (id) => dispatch => {
  dispatch({
    type: VERIFY_ID_TOKEN,
    payload: id
  });
}

//  Action creator
export function getByUIDFulfilled(data) {
  return dispatch => {
    dispatch({
      type: VERIFY_ID_TOKEN_FULFILLED,
      data: data
    });
  };
}

// Epic

export const getByUIDEpic = action$ => action$.pipe(
  ofType(VERIFY_ID_TOKEN),
  mergeMap(action =>
    UserAPI.getByUID(action.payload.id)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            'Verificado ..',
            'success',
            500
          );
          return getByUIDFulfilled(response.data);
        })
      )
  )
);

export const userEpics = [
  getByUIDEpic
]
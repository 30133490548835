import React from "react";
import { authRoles } from "../../auth/authRoles";

const newsRoutes = [
  {
    path: "/novedades/nuevo",
    component: React.lazy(() => import("./NewsForm")),
    auth: authRoles.admin
  },
  {
    path: "/novedades/editar/:id",
    component: React.lazy(() => import("./NewsForm")),
    auth: authRoles.admin
  },
  {
    path: "/novedades/listado",
    component: React.lazy(() => import("./News")),
    auth: authRoles.admin
  },
  {
    path: "/novedades-categorias/nuevo",
    component: React.lazy(() => import("./NewsCategoryForm")),
    auth: authRoles.admin
  },
  {
    path: "/novedades-categorias/editar/:id",
    component: React.lazy(() => import("./NewsCategoryForm")),
    auth: authRoles.admin
  },
  {
    path: "/novedades-categorias/listado",
    component: React.lazy(() => import("./NewsCategories")),
    auth: authRoles.admin
  },
  {
    path: "/novedades-tags/nuevo",
    component: React.lazy(() => import("./NewsTagForm")),
    auth: authRoles.admin
  },
  {
    path: "/novedades-tags/editar/:id",
    component: React.lazy(() => import("./NewsTagForm")),
    auth: authRoles.admin
  },
  {
    path: "/novedades-tags/listado",
    component: React.lazy(() => import("./NewsTags")),
    auth: authRoles.admin
  },
  {
    path: "/novedades/asignar-novedades/:id",
    component: React.lazy(() => import("./NewsAssignNews")),
    auth: authRoles.admin
  },
  {
    path: "/novedades/asignar-novedades",
    component: React.lazy(() => import("./NewsAssignNews")),
    auth: authRoles.admin
  },
  {
    path: "/novedades/asignar-clientes",
    component: React.lazy(() => import("./NewsAssignTenants")),
    auth: authRoles.admin
  },
];

export default newsRoutes;

import React from 'react';
import styles from './index.module.css'

interface Props {
  index: number | JSX.Element;
  status: 'idle' | 'active' | 'done';
  label: string;
}

export default function ProgressTrackerButton ({ index, status, label } : Props) {
  let style = `${styles.progressTrackerButton} `;
  if (status === 'active') {
    style += styles.active;
  } else if (status === 'done') {
    style += styles.done;
  }

  return (
      <div className={style}>
        {index}
        <label className={styles.label}>
          {label}
        </label>
      </div>
  );
};
import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { NotificationType } from '../models/NotificationType';


class NotificationTypeAPI extends ResourceService<NotificationType> {

    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.notificacionesTipos,
            'notificationType',
            NotificationType
        );
    }

}

export default new NotificationTypeAPI();
import React from 'react';
import styles from './index.module.css'

interface Props {
  secondary?: boolean;
  size?: 'normal' | 'small' | 'verySmall';
  children: React.ReactNode;
  onClick?: () => void;
}

export default function Button ({ secondary = false, size = 'normal', children, onClick}: Props) {

  const style = secondary ? styles.secondary : styles.primary;

  return (
    <button className={[ style, styles[size] ].join(' ')} onClick={onClick}>
      {children}
    </button>
  );
};

import React from 'react';
import styles from './index.module.css'

interface Props {
  children: JSX.Element;
  title: string;
  stepList: string[];
  activeStep?: number;
}

export default function StepComponentWrapper ({ title, activeStep = 3, children, stepList }: Props) {
  return (
    <div className={styles.componentContainer}>
      {children}
      <div className={styles.stepsComponentContainer}>
        <div className={styles.container}>
          <div className={styles.title}> {title} </div>
          {stepList.map( (step, idx) => {

            let status: "idle" | "done" | "active" = 'idle';
            let styleStepButton = `${styles.textButton} `;

            if (idx +1 < activeStep) {
              status = 'done';
              styleStepButton +=  styles.done
            } else if(idx +1 === activeStep) {
              status = 'active';
              styleStepButton += styles.active;
            }

            return (
              <div className={styles.itemsContainer} key={idx}>
                <div className={styles.itemContainer}> 
                  <div className={styleStepButton}> {status === 'done' ? <svg xmlns="http://www.w3.org/2000/svg" height="10px" viewBox="0 0 24 24" width="10px" fill="#575AEF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg> : idx+1}
                  </div>
                  <div className={styles.step}> {step} </div>
                </div>
              </div>
            )
          }
          )}
        </div>
      </div>
    </div>
  );
};

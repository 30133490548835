import { Resource } from '../core/Resource';
import { Role } from '../models/Role';

export class User extends Resource {
  public id!: number;
  public uid!: number;
  public role!: Role;
  public last_login!: Date;
  public date_joined!: Date;
  public first_name!: string;
  public last_name!: string;

  deserialize(input: any) {
    Object.assign(this, input);

    if (input.role) {          
      this.role = typeof(input.role) === 'number' ? input.role : new Role().deserialize(input.role);
    }

    return this;
  }

  serialize() {
    const user = Object.assign(this as any, {});
    return user;
  }

}

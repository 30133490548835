//Libraries + Hooks
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { CircularProgress, Snackbar, SnackbarContent } from '@material-ui/core';
import { snackbarService } from 'uno-material-ui';
import history from '../../history';

//Action + Reducers + API + Fetch + env + Axios
import { createTenant, updateTenant } from '../../app/redux/actions/TenantActions';
import prepaidAPI from "../../app/apis/Prepaids";
import serviceAPI from "../../app/apis/Services";
import templateAPI from "../../app/apis/Templates";
import tenantAPI from "../../app/apis/Tenants";
import { Prepaid } from '../../app/models/Prepaid';
import { Service } from '../../app/models/Service';
import { Template } from '../../app/models/Template';
import { Tenant } from '../../app/models/Tenant';
import { environment } from '../../app/environments/environment';
import axios from 'axios';

//Components + Form Pages + Validators + Styles
import Button from '../../components/Button';
import GoBack from '../../components/GoBack';
import ProgressTracker from '../../components/ProgressTracker';
import YourData from '../YourData';
import YourWeb from '../YourWeb';
import Confirm from '../Confirm';
import styles from './index.module.css';
import AddInformation from '../AddInformation';
import { Data } from '../../components/TimeTable';
import { 
  validateBusinessCity, 
  validateBusinessName, 
  validateBusinessProvince, 
  validateBusinessStreet, 
  validateBusinessStreetNumber, 
  validateCompanyName, 
  validateCuit, 
  validateAnalytics, 
  validateEmail, 
  validateFacebook, 
  validateInstagram, 
  validateLinkedin, 
  validateLinkFol, 
  validatePhoneNumber1, 
  validatePhoneNumber2, 
  validatePhoneNumber3, 
  validatePhoneNumber4, 
  validateSlogan, 
  validateSwissAccountNumber, 
  validateTaxData, 
  validateTwitter, 
  validateWebDomain, 
  validateWhatsApp, 
  validateYouTube 
} from '../../utils/validate';

function ClientForm() {
  //Redux Hooks
  useSelector(state => state)
  const dispatch = useDispatch()
  
  //States Page 1
  const [logoImage, setLogoImage] = useState<File>()
  const [businessName, setBusinessName] = useState<string>()
  const [companyName, setCompanyName] = useState<string>()
  const [cuit, setCuit] = useState<string>()
  const [analytics, setAnalytics] = useState<string>()
  const [linkFol, setLinkFol] = useState<string>()
  const [webDomain, setWebDomain] = useState<string>()
  const [swissAccountNumber, setSwissAccountNumber] = useState<string>()
  const [businessProvince, setBusinessProvince] = useState<string>()
  const [businessCity, setBusinessCity] = useState<string>()
  const [businessStreet, setBusinessStreet] = useState<string>()
  const [businessStreetNumber, setBusinessStreetNumber] = useState<string>()
  const [whatsApp, setWhatsApp] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [taxData, setTaxData] = useState<string>()
  const [instagram, setInstagram] = useState<string>()
  const [facebook, setFacebook] = useState<string>()
  const [linkedin, setLinkedin] = useState<string>()
  const [twitter, setTwitter] = useState<string>()
  const [youTube, setYouTube] = useState<string>()
  const [phoneNumber1, setPhoneNumber1] = useState<string>()
  const [phoneNumber2, setPhoneNumber2] = useState<string>()
  const [phoneNumber3, setPhoneNumber3] = useState<string>()
  const [phoneNumber4, setPhoneNumber4] = useState<string>()
  const [location, setLocation] = useState<Array<{name: string, province_name: string, city_name: string, street_name: string, street_lvl: string, coordinates: Object}>>()

  //States Page 2
  const [primaryColor, setPrimaryColor] = useState<string>('')
  const [secondaryColor, setSecondaryColor] = useState<string>('')
  const [templates, setTemplates] = useState<Array<{id: number, preview: string, thumbnail: string}>>([])
  const [templateId, setTemplateId] = useState<number>()
  const [, setTemplateThumbnail] = useState<string>() 
  
  //States Page 3 + Schedule Initial State
  const [businessImage, setBusinessImage] = useState<File>()
  const [staffImage, setStaffImage] = useState<File>()
  const [employees, setEmployees] = useState<Array<{photo: File, name: string, position: string}>>([])
  const [slogan, setSlogan] = useState<string>()
  const [businessDescription, setBusinessDescription] = useState<any>()
  const emptySchedule = [{id: '1', day: 'Lunes', from: '', to: ''},{id: '2',  day: 'Martes', from: '', to: ''},{id: '3',  day: 'Miercoles', from: '', to: ''},{id: '4', day: 'Jueves', from: '', to: ''},{ id: '5', day: 'Viernes', from: '', to: ''},{id: '6',  day: 'Sabado', from: '', to: ''},{id: '1',  day: 'Domingo', from: '', to: ''}]
  const [scheduleData, setScheduleData] = useState<Array<{day: string, from: string, to: string}>>(emptySchedule)
  const [newScheduleData, setNewScheduleData] = useState<{}>()
  
  //States Page 4
  const [toggleSnackbar, setToggleSnackbar] = useState(false)
  const [services, setServices] = useState<Array<{id: string, name: string, type: string, isSelected: boolean}>>([])
  const [prepaids, setPrepaids] = useState<Array<{id: string, name: string, type: string, isSelected: boolean}>>([])

  //Form Page State
  const [activeStep, setActiveStep] = useState(1)

  //Tenant States used when fetching tenant info + useParams Hook to get Tenant ID.
  const params  = useParams<{id: string}>()
  const [tenantServices, setTenantServices] = useState<number[]>([])
  const [tenantPrepaids, setTenantPrepaids] = useState<number[]>([])
  const [aboutUsId, setAboutUsId] = useState<number>()
  const [showLoader, setShowLoader] = useState<boolean>(false)

  //UseEffect 1
  //Fetches Prepaids/Services/Templates
  useEffect( () => {
    //Prepaids
    prepaidAPI.getAll().subscribe((response) => {
      let prepaidsData = Object.values(response.data)
      let prepaids: any = prepaidsData.map( prepaid => {
        let prepaidsDeserialized = new Prepaid().deserialize(prepaid)
        if(params.id) {
          return {
            id: prepaidsDeserialized.id,
            name: prepaidsDeserialized.name,
            type: prepaidsDeserialized.type,
            isSelected: tenantPrepaids.includes(prepaidsDeserialized.id),
          }
        } else {
          return {
            id: prepaidsDeserialized.id,
            name: prepaidsDeserialized.name,
            type: prepaidsDeserialized.type,
            isSelected: false
          }
        }
      })
      setPrepaids(prepaids)
    });

    //Services
    serviceAPI.getAll().subscribe((response) => {
      const servicesData = Object.values(response.data)
      const services: any = servicesData.map( service => {
        const servicesDeserialized = new Service().deserialize(service)
        if(params.id) {
          return {
            id: servicesDeserialized.id,
            name: servicesDeserialized.title,
            isSelected: tenantServices.includes(servicesDeserialized.id),
          }
        } else {
          return {
            id: servicesDeserialized.id,
            name: servicesDeserialized.title,
            isSelected: false,
          }
        }
      }) 
      setServices(services)
    });

    //Templates
    templateAPI.getAll().subscribe((response) => {
      let templateData = Object.values(response.data)
      let templates: any = templateData.map( template => {
        let templatesDeserialized = new Template().deserialize(template)
        return {
          id: templatesDeserialized.id,
          preview: templatesDeserialized.live_preview,
          thumbnail: templatesDeserialized.thumbnail
        }
      })
      setTemplates(templates)
    });
  }, [tenantServices, tenantPrepaids, params.id])
  
  //UseEffect 2
  //Fetches Tenant info with the respective ID and sets form field states. Some info is transformed to be displayed correctly. 
  useEffect( () => {

    let tenant: Tenant;
    if(!params?.id) return;
    setShowLoader(true);
    tenantAPI.getOne(Number(params.id)).subscribe(
      (response) => {
        tenant = new Tenant().deserialize(response.data);
        setCuit(tenant.cuit)
        setAnalytics(tenant.analytics)
        setLogoImage(tenant.logo)
        setPrimaryColor(tenant.main_color!)
        setSecondaryColor(tenant.secondary_color!)
        setTemplateId(tenant.template?.id)
        setSwissAccountNumber(tenant.suizo_account)
        setLinkFol(tenant.fol_link)
        setBusinessName(tenant.fantasy_name)
        setCompanyName(tenant.name)                
        setWebDomain(tenant.domain)
        setWhatsApp(tenant.mobile_phone)
        setSlogan(tenant.slogan)
        setYouTube(tenant.youtube)
        setTwitter(tenant.twitter)
        setInstagram(tenant.instagram)
        setFacebook(tenant.facebook) 
        setLinkedin(tenant.linkedin)
        setTenantServices(tenant.services)
        setTenantPrepaids(tenant.prepaids)
        tenant.pharmacies!.map(element => {
          return {
            name: 'Sede Central',
            province: setBusinessProvince(element.province.name),
            city: setBusinessCity(element.city.name),
            street_name: setBusinessStreet(element.street_name),
            street_lvl: setBusinessStreetNumber(element.street_lvl)
          }
        })
        setTemplates([{
          id: tenant.template?.id!,
          thumbnail: tenant.template?.thumbnail as any, //thumbnail del back es de tipo File. Se debe cambiar en ClientForm
          preview: tenant.template?.live_preview!,
        }])
        setTemplateId(tenant.template?.id)
        setEmployees(tenant.specialists);
        setAboutUsId(tenant.about_us?.id)
        setStaffImage(tenant.about_us?.team)
        setBusinessImage(tenant.about_us?.store)
        setSlogan(tenant.about_us?.slogan)
        setBusinessDescription(tenant.about_us?.description)
        setPhoneNumber1(tenant.phone_main)
        setPhoneNumber2(tenant.phone_second)
        setPhoneNumber3(tenant.phone_third)
        setPhoneNumber4(tenant.phone_fourth)    
        setEmail(tenant.email)
        setPhoneNumber1(tenant.phone_main)
        setTaxData(tenant.fiscal_data)
        const schedule: Array<{id: string, day: string, from: string, to: string, disabled: boolean}> = emptySchedule.map( sch => {
          const tenantObject: any = tenant.schedules.find(element => sch.day === element.day)
          const workingHours = tenantObject && tenantObject.hours.match(/[0-9]{1,2}:[0-9]{1,2}/g)
          if(workingHours) {
            return {
            id: tenantObject.id,
            day: sch.day,
            from: workingHours[0],
            to: workingHours[1],
            disabled: true,
            } 
          } else{
            return {
              ...sch,
              disabled: false,
            }
          }
        })
        setScheduleData(schedule)
        scheduleToBack(schedule)
        setShowLoader(false)
      },
      (error) => {
        snackbarService.showSnackbar(
          "El cliente solicitado no existe",
          "error",
          500
        );
        history.push({
          pathname: "/clientes/listado",
        });
        return null;
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //useEffect 3
  //Scroll to top when changing form page
  useEffect( () => {
    //The form page renders inside a div with className scrollbar-container, that's why window.scrollTo won't work.
    document.getElementsByClassName('scrollable-content')[0].scrollTo(0,0)
  }, [activeStep])


  let tenant = {
    main_color: primaryColor,
    new_specialists_list: employees.map( employee => 
      ({
        ...employee,
        photo_src: params.id ? employee.photo : URL.createObjectURL(employee.photo),
        tempId: '_' + Math.random().toString(36).substr(2, 9),
      })),
    new_schedules_list: newScheduleData !== undefined ? newScheduleData : [],
    new_pharmacies_list: location,
    services_list: services.filter(service => service.isSelected === true).map(serviceSelected => {return `${serviceSelected.id}`}),
    prepaids_list: prepaids.filter(prepaid => prepaid.isSelected === true).map(prepaidSelected => {return `${prepaidSelected.id}`}),
    promotions_list: [],
    news_list: [],
    about_us: {
      id: params.id ? aboutUsId : null,
      team: typeof(staffImage) === 'string' ? '' : staffImage,
      store: typeof(businessImage) === 'string' ? '' : businessImage,
      slogan: slogan,
      description: businessDescription,
      },
    email: email,
    name: businessName,
    fantasy_name: companyName,
    phone_main: phoneNumber1,
    phone_second: phoneNumber2 !== undefined ? phoneNumber2 : '',
    phone_third: phoneNumber3 !== undefined ? phoneNumber3 : '',
    phone_fourth: phoneNumber4 !== undefined ? phoneNumber4 : '',
    mobile_phone: whatsApp,
    suizo_account: swissAccountNumber,
    cuit: cuit,
    analytics: analytics,
    fiscal_data: taxData,
    logo: typeof(logoImage) === 'string' ? '' : logoImage,
    geo: '',
    domain: webDomain,
    fol_link: linkFol,
    facebook: facebook !== undefined ? facebook : '',
    twitter: twitter !== undefined ? twitter : '',
    instagram: instagram !== undefined ? instagram : '',
    linkedin: linkedin !== undefined ? linkedin : '',
    youtube: youTube !== undefined ? youTube : '',
    template: templateId,
    secondary_color: secondaryColor,
    displayMainColorPicker: false,
    displaySecondaryColorPicker: false,        
  }

  //Back + Save functions
  const onBack = () => setActiveStep(activeStep - 1)
  const onSave = () => console.log(activeStep)

  //Next Step + updateTenant + createTenant
  const onSaveAndContinue = () => {
    if(activeStep === 3 && params.id) {
      submit() && dispatch(updateTenant({...tenant, id: params.id}))
    } else if(activeStep === 4 && !params.id){
      submit() && dispatch(createTenant(tenant))
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  /**
   * Sets the location (in a local state) of the company using Google Geo API. Returns an array with the name, province, city, street name, street number and
   * its coordinates
   * 
   * @param {string} businessProvince
   * @param {string} businessCity
   * @param {string} businessStreet
   * @param {number} businessStreetNumber
   * @param {string} gmapsApiKey 
   * 
   * @returns {Object[]} location - Includes all the params mentioned plus the coordinates of the company.
  */
  const clientLocation = async () => {
    const gmapsApiKey = environment.gmapsApiKey;
    const response = await 
      axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${businessStreet} ${businessStreetNumber},+${businessCity},+${businessProvince}&key=${gmapsApiKey}`)
    if (response.data.results.length > 0){
      setLocation([{
        name: "Sede central",
        province_name: businessProvince!,
        city_name: businessCity!,
        street_name: businessStreet!,
        street_lvl: businessStreetNumber!,
        coordinates: response.data.results[0].geometry.location
      }])                 
    }
  };

  //Conditional to execute clientLocation function.
  if(activeStep !== 1 && !location) {
    clientLocation()
  } else if(activeStep === 1 && location) {
    setLocation(undefined)
  }

  //Function that transforms schedule to send to back
  function scheduleToBack(data: Data[]) {
    return setNewScheduleData(data.map( (data, idx) => {
      if (!data.disabled) return {
        day: data.day,
        hours: 'Cerrado',
      }
      return {
        day: data.day,
        hours: `Desde las ${data.from} hasta las ${data.to}`,
      }
    }))
  };

  //Form Fields Validation
  const businessNameValid = validateBusinessName(businessName)
  const cuitValid = validateCuit(cuit)
  const analyticsValid = validateAnalytics(analytics)
  const businessProvinceValid = validateBusinessProvince(businessProvince)
  const businessCityValid = validateBusinessCity(businessCity)
  const businessStreetValid = validateBusinessStreet(businessStreet)
  const businessStreetNumberValid = validateBusinessStreetNumber(businessStreetNumber)
  const emailValid = validateEmail(email)
  const taxDataValid = validateTaxData(taxData)
  const companyNameValid = validateCompanyName(companyName)
  const linkFolValid = validateLinkFol(linkFol)
  const webDomainValid = validateWebDomain(webDomain)
  const swissAccountNumberValid = validateSwissAccountNumber(swissAccountNumber)
  const whatsAppValid = validateWhatsApp(whatsApp)
  const instagramValid = validateInstagram(instagram)
  const youTubeValid = validateYouTube(youTube)
  const facebookValid = validateFacebook(facebook)
  const linkedinValid = validateLinkedin(linkedin)
  const twitterValid = validateTwitter(twitter)
  const sloganValid = validateSlogan(slogan)
  const phoneNumber1Valid = validatePhoneNumber1(phoneNumber1)
  const phoneNumber2Valid = validatePhoneNumber2(phoneNumber2)
  const phoneNumber3Valid = validatePhoneNumber3(phoneNumber3)
  const phoneNumber4Valid = validatePhoneNumber4(phoneNumber4)
  
  //Snackbar to display when submitting with invalid fields
  const snackbarArray = [
    {name: "Nombre de farmacia", valid: businessNameValid}, 
    {name: "Cuit", valid: cuitValid}, 
    {name: "Analytics", valid: analyticsValid}, 
    {name: "Provincia", valid: businessProvinceValid},
    {name: "Ciudad", valid: businessCityValid},
    {name: "Calle", valid: businessStreetValid}, 
    {name: "Numero de calle", valid: businessStreetNumberValid},
    {name: "Email", valid: emailValid},
    {name: "Tax Data", valid: taxDataValid},
    {name: "Razon social", valid: companyNameValid},
    {name: "Link de Fol", valid: linkFolValid},
    {name: "Dominio Web", valid: webDomainValid},
    {name: "Numero de cuenta en Suizo", valid: swissAccountNumberValid},
    {name: "WhatsApp", valid: whatsAppValid},
    {name: "Twitter", valid: twitterValid}, 
    {name: "Slogan", valid: sloganValid}, 
    {name: "Telefono numero 1", valid: phoneNumber1Valid}, 
    {name: "Logo", valid: logoImage}]

  //Boolean constant that its true if all fields are valid, and false if otherwise.
  const canSubmit = businessCityValid && cuitValid && businessProvinceValid && businessCityValid
    && businessStreetNumberValid && businessStreetValid && emailValid
    && taxDataValid && companyNameValid && linkFolValid && webDomainValid && swissAccountNumberValid &&
    whatsAppValid && sloganValid && phoneNumber1Valid && logoImage && primaryColor 
    && secondaryColor && staffImage;

  //Submit Function -> if true allows to create/update tenant. If false opens the snackbar to amend invalid fields.
  const submit = () => {
    if(canSubmit) {
      return true
    } else {
      setToggleSnackbar(true)
      return false
    }
  }

  return (
    <>
    { showLoader ?
        <div className={styles.loading}>
          <img src="/assets/images/logo-circle.svg" alt="" />
          <CircularProgress />
        </div>
    :
    <div className={styles.container}>
      <div className={styles.layout}>
        { activeStep !== 1 ? 
          <div>
            <GoBack 
              onClick={onBack}/>
          </div> :
          ''
        }
        <div className={styles.progressTracker}>
          { params.id ? 
          
            <ProgressTracker
              labels={['Tus Datos', 'Tu Web', 'Agrega el contenido']}
              selectedNod={activeStep}
            />
            :
            <ProgressTracker
              labels={['Tus Datos', 'Tu Web', 'Agrega el contenido', 'Confirmacion del pedido', 'Pago']}
              selectedNod={activeStep}
            />
        
          }
        </div>
        <div className={styles.bodyContainer}>
          <div>
            { activeStep === 1 &&
              <YourData 
                logoImageChange={image => setLogoImage(image)}
                logoImage={logoImage!}
                businessNameChange={ value => setBusinessName(value)}
                businessName={businessName}
                businessNameError={ !businessNameValid ? 'Este campo es requerido para enviar el formulario' : '' }
                validateBusinessName={validateBusinessName}
                companyNameChange={ value => setCompanyName(value)}
                companyName={companyName}
                companyNameError={!companyNameValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateCompanyName={validateCompanyName}
                cuitChange={ value => setCuit(value)}
                analyticsChange={ value => setAnalytics(value)}
                cuit={cuit}
                analytics={analytics}
                cuitError={!cuitValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateCuit={validateCuit}
                analyticsError={!analyticsValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateAnalytics={validateAnalytics}
                linkFolChange={ value => setLinkFol(value)}
                linkFol={linkFol}
                linkFolError={!linkFolValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateLinkFol={validateLinkFol}
                webDomainChange={ value => setWebDomain(value)}
                webDomain={webDomain}
                webDomainError={!webDomainValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateWebDomain={validateWebDomain}
                swissAccountNumberChange={ value => setSwissAccountNumber(value)}
                swissAccountNumber={swissAccountNumber}
                swissAccountNumberError={!swissAccountNumberValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateSwissAccountNumber={validateSwissAccountNumber}
                businessProvinceChange={ value => setBusinessProvince(value)}
                businessProvince={businessProvince}
                businessProvinceError={!businessProvinceValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateBusinessProvince={validateBusinessProvince}
                businessCityChange={ value => setBusinessCity(value)}
                businessCity={businessCity}
                businessCityError={!businessCityValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateBusinessCity={validateBusinessCity}
                businessStreetChange={ value => setBusinessStreet(value)}
                businessStreet={businessStreet}
                businessStreetError={!businessStreetValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateBusinessStreet={validateBusinessStreet}
                businessStreetNumberChange={ value => setBusinessStreetNumber(value)}
                businessStreetNumber={businessStreetNumber}
                businessStreetNumberError={!businessStreetNumberValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateBusinessStreetNumber={validateBusinessStreetNumber}
                whatsAppChange={ value => setWhatsApp(value)}
                whatsApp={whatsApp}
                whatsAppError={!whatsAppValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateWhatsApp={validateWhatsApp}
                emailChange={ value => setEmail(value)}
                email={email}
                emailError={!emailValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateEmail={validateEmail}
                taxDataChange={ value => setTaxData(value)}
                taxData={taxData}
                taxDataError={!taxDataValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateTaxData={validateTaxData}
                instagramChange={ value => setInstagram(value)}
                instagram={instagram}
                instagramError={!instagramValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateInstagram={validateInstagram}
                facebookChange={ value => setFacebook(value)}
                facebook={facebook}
                facebookError={!facebookValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateFacebook={validateFacebook}
                linkedinChange={ value => setLinkedin(value)}
                linkedin={linkedin}
                linkedinError={!linkedinValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateLinkedin={validateLinkedin}
                twitterChange={ value => setTwitter(value)}
                twitter={twitter}
                twitterError={!twitterValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateTwitter={validateTwitter}
                youTubeChange={ value => setYouTube(value)}
                youTube={youTube}
                youTubeError={!youTubeValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateYouTube={validateYouTube}
                phoneNumber1Change={ value => setPhoneNumber1(value)}
                phoneNumber1={phoneNumber1}
                phoneNumber1Error={!phoneNumber1Valid ? 'Este campo es requerido para enviar el formulario' : ''}
                validatePhoneNumber1={validatePhoneNumber1}
                phoneNumber2Change={ value => setPhoneNumber2(value)}
                phoneNumber2={phoneNumber2}
                phoneNumber2Error={!phoneNumber2Valid ? 'Este campo es requerido para enviar el formulario' : ''}
                validatePhoneNumber2={validatePhoneNumber2}
                phoneNumber3Change={ value => setPhoneNumber3(value)}
                phoneNumber3={phoneNumber3}
                phoneNumber3Error={!phoneNumber3Valid ? 'Este campo es requerido para enviar el formulario' : ''}
                validatePhoneNumber3={validatePhoneNumber3}
                phoneNumber4Change={ value => setPhoneNumber4(value)}
                phoneNumber4={phoneNumber4}
                phoneNumber4Error={!phoneNumber4Valid ? 'Este campo es requerido para enviar el formulario' : ''}
                validatePhoneNumber4={validatePhoneNumber4}
              />
            }
            { activeStep === 2 &&
              <YourWeb 
                logo={logoImage!}
                primaryColorSelected={color => setPrimaryColor(color)}
                secondaryColorSelected={color => setSecondaryColor(color)}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                activeOption={templateId ? templateId : 0}
                templateOptions={templates}
                templateSelected={(id, thumbnail) => 
                  {
                    setTemplateId(id); 
                    setTemplateThumbnail(thumbnail)
                  }}
              />}
            {activeStep === 3 &&             
              <AddInformation 
                businessImageChange={image => setBusinessImage(image)}
                businessImage={businessImage!}
                scheduleData={scheduleData}
                onScheduleChange={newSchedule => 
                  {
                    scheduleToBack(newSchedule); 
                    setScheduleData(newSchedule)
                  }
                }
                staffImageChange={image => setStaffImage(image)}
                staffImage={staffImage!}
                employees={employees!}
                employeesChange={ employees => setEmployees(employees)}
                businessDescriptionChange={value => setBusinessDescription(value)}
                businessDescription={businessDescription}
                sloganChange={value => setSlogan(value)}
                slogan={slogan}
                sloganError={!sloganValid ? 'Este campo es requerido para enviar el formulario' : ''}
                validateSlogan={validateSlogan}
                dataPrepaids={prepaids}
                dataServices={services}
                onServicesSelected={selected => 
                  { 
                    setServices(selected); 
                  }
                }
                onPrepaidsSelected={selected => 
                  { 
                    setPrepaids(selected);
                  }
                }
              />
            }
            {activeStep === 4 &&
              <Confirm
                logo={logoImage!}
                primaryColorSelected={color => setPrimaryColor(color)}
                secondaryColorSelected={color => setSecondaryColor(color)}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                templateIdSelected={templateId!}
                templateSelected={templates.filter(template => template.id === templateId && template)}/>
            }
            <div className={styles.buttons}>
              <Button 
                children='guardar'
                secondary={true}
                onClick={onSave}
              />
              <Button 
                children={ (activeStep === 3 && params.id) ? 'finalizar cambios' : 'guardar y continuar'}
                onClick={onSaveAndContinue}
              />
              {toggleSnackbar ? 
                <Snackbar
                  open={toggleSnackbar}
                  autoHideDuration={4000}
                  
                  onClose={() => setToggleSnackbar(!toggleSnackbar)}
                  anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                >
                  <SnackbarContent 
                    style={{
                      backgroundColor:'#418aeb',
                    }}
                    message={
                      <ul className={styles.snackbarMessageList}>
                        {snackbarArray.filter(element => element.valid === false)
                          .map( (el,idx) => { 
                            return <li
                             key={idx} className={styles.snackbarMessageItemList}>
                             Campo incompleto: {el.name} 
                            </li>
                          })
                        }
                      </ul>
                    }
                  />
                </Snackbar> :
                ''}
            </div>
          </div>
        </div>
      </div>
    </div>
    }
    </>
  );
};

export default withRouter(connect()(ClientForm));

import React from "react";
import { authRoles } from "../../auth/authRoles";

const promotionsRoutes = [
  {
    path: "/promociones/nuevo",
    component: React.lazy(() => import("./PromotionForm")),
    auth: authRoles.admin
  }, 
  {
    path: "/promociones/editar/:id",
    component: React.lazy(() => import("./PromotionForm")),
    auth: authRoles.admin
  },
  {
    path: "/promociones/listado",
    component: React.lazy(() => import("./Promotions")),
    auth: authRoles.admin
  },
  {
    path: "/promociones/asignar-promociones/:id",
    component: React.lazy(() => import("./PromotionAssignPromotions")),
    auth: authRoles.admin
  },
  {
    path: "/promociones/asignar-promociones",
    component: React.lazy(() => import("./PromotionAssignPromotions")),
    auth: authRoles.admin
  },
  {
    path: "/promociones/asignar-clientes",
    component: React.lazy(() => import("./PromotionAssignTenants")),
    auth: authRoles.admin
  },
];

export default promotionsRoutes;

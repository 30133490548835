import React from 'react';
import styles from './index.module.css';

interface Props {
  subtitle: string;
};

export default function SectionSubtitle ({ subtitle }: Props) {
  return (
    <div className={styles.container}>
      <span 
        className={styles.subtitle}> 
        { subtitle } 
      </span>
    </div>
  );
};


import PharmacyAPI from "../../apis/Pharmacies"
import { map, mergeMap } from "rxjs/internal/operators";
import { ofType } from "redux-observable";

export const FETCH_PHARMACY_DATA = "PHARMACY_FETCH_DATA";
export const FETCH_PHARMACY_DATA_FULFILLED = "PHARMACY_FETCH_DATA_FULFILLED";

//  Action creator
export const fetchPharmacies = () => dispatch => {
  dispatch({
    type: FETCH_PHARMACY_DATA,
  });
}

//  Action creator
export function fetchPharmaciesFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_PHARMACY_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchPharmaciesEpic = action$ => action$.pipe(
  ofType(FETCH_PHARMACY_DATA),
  mergeMap(action =>
   PharmacyAPI.getAll()
      .pipe(
        map(response => fetchPharmaciesFulfilled(response.data))
      )
  )
);


export const pharmacyEpics = [
  fetchPharmaciesEpic,
];

import { Promotion } from "../../models/Promotion"
import PromotionAPI from "../../apis/Promotions"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

export const PROMOTION_ERROR = "PROMOTION_ERROR"
export const CREATE_PROMOTION = "CREATE_PROMOTION";
export const CREATE_PROMOTION_FULFILLED = "CREATE_PROMOTION_FULFILLED";
export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const UPDATE_PROMOTION_FULFILLED = "UPDATE_PROMOTION_FULFILLED";
export const FETCH_PROMOTION_DATA = "PROMOTION_FETCH_DATA";
export const FETCH_PROMOTION_DATA_FULFILLED = "PROMOTION_FETCH_DATA_FULFILLED";
export const DELETE_PROMOTION = "DELETE_PROMOTION"
export const DELETE_PROMOTION_FULFILLED = "DELETE_PROMOTION_FULFILLED"

//  Action creator
export const createPromotion = ({id, title, description, link, image, use_cta}) => dispatch => {
  dispatch({
    type: CREATE_PROMOTION,
    payload: {id, title, description, link, image, use_cta}
  });
}

//  Action creator
export function createPromotionFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_PROMOTION_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createPromotionEpic = action$ => action$.pipe(
  ofType(CREATE_PROMOTION),
  mergeMap(action => {
    const promotion = new Promotion().deserialize(action.payload);
    return PromotionAPI.create(promotion)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Promoción ${promotion.title} creada con éxito`, 
            'success',
            500
          );
          history.push({
            pathname: "/promociones/listado"
          });
          return createPromotionFulfilled(response.data);
        }),
        catchError(error => of({type: PROMOTION_ERROR}))
      )
    }
  )
);

//  Action creator
export const updatePromotion =
  ({ ...fields }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_PROMOTION,
      payload: { ...fields },
    });
  };

//  Action creator
export function updatePromotionFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROMOTION_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updatePromotionEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_PROMOTION),
    mergeMap((action) => {
      const promotion = new Promotion().deserialize(action.payload);
      return PromotionAPI.update(promotion).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Promoción ${promotion.title} editada con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/promociones/listado",
          });
          return updatePromotionFulfilled(response.data);
        })
      );
    })
  );


//  Action creator
export const fetchPromotions = () => dispatch => {
  dispatch({
    type: FETCH_PROMOTION_DATA,
  });
}

//  Action creator
export function fetchPromotionsFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_PROMOTION_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchPromotionsEpic = action$ => action$.pipe(
  ofType(FETCH_PROMOTION_DATA),
  mergeMap(action =>
    PromotionAPI.getAll()
      .pipe(
        map(response => fetchPromotionsFulfilled(response.data))
      )
  )
);


//  Action creator
export const deletePromotion = (id) => dispatch => {
  dispatch({
    type: DELETE_PROMOTION,
    payload: id
  });
}

//  Action creator
export function deletePromotionFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_PROMOTION_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deletePromotionEpic = action$ => action$.pipe(
  ofType(DELETE_PROMOTION),
  mergeMap(action =>
    PromotionAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito la promocion ' + action.payload.name,
            'success',
            500
          );
          return deletePromotionFulfilled(action.payload.id);
        })
      )
  )
);

export const promotionEpics = [
  createPromotionEpic,
  updatePromotionEpic,
  fetchPromotionsEpic,
  deletePromotionEpic
]


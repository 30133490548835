import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { retryWhen, catchError, map } from "rxjs/operators";
import { snackbarService } from "uno-material-ui";
import { throwError } from "rxjs";
import { ResourceService } from "../core/ResourceService";
import { genericRetryStrategy } from "../core/genericRetryStrategy";
import { environment } from "../environments/environment";
import { Newsletter } from "../models/Newsletter";


class NewsletterAPI extends ResourceService<Newsletter> {
  constructor() {
    super(environment.api.base, environment.api.newsletter, "newsletter", Newsletter);
  }

  public getCSVFile(id: number, params: {} = {}): AxiosObservable<Newsletter> {
    return this.axiosInstance.get(`${this.url}/export_csv/${id}`)
    .pipe(
      retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
      catchError((error) => {
        snackbarService.showSnackbar("Hubo un problema al descargar el archivo.", "error", 500);
        return throwError(error);
      }),
      map((res: any) => {
        return res;
      })
    )
  }
}

export default new NewsletterAPI();

import { Resource } from '../core/Resource';

export class Role extends Resource {
  public id!: number;
  public name!: string;
  public description?: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const role = Object.assign(this as any, {});
    return role;
  }

}

import { Tenant } from "../../models/Tenant";
import tenantAPI from "../../apis/Tenants";
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

export const TENANT_ERROR = "TENANT_ERROR";
export const CREATE_TENANT = "CREATE_TENANT";
export const CREATE_TENANT_FULFILLED = "CREATE_TENANT_FULFILLED";
export const UPDATE_TENANT = "UPDATE_TENANT";
export const UPDATE_TENANT_FULFILLED = "UPDATE_TENANT_FULFILLED";
export const FETCH_TENANT_DATA = "TENANT_FETCH_DATA";
export const FETCH_TENANT_DATA_FULFILLED = "TENANT_FETCH_DATA_FULFILLED";
export const DELETE_TENANT = "DELETE_TENANT";
export const DELETE_TENANT_FULFILLED = "DELETE_TENANT_FULFILLED";
export const BUILD_TENANT_SITE = "BUILD_TENANT_SITE";
export const BUILD_TENANT_SITE_FULFILLED = "BUILD_TENANT_SITE_FULFILLED";


//  Action creator
export const createTenant =
  ({ ...fields }) =>
  (dispatch) => {
    dispatch({
      type: CREATE_TENANT,
      payload: { ...fields },
    });
  };

//  Action creator
export function createTenantFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: CREATE_TENANT_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const createTenantEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_TENANT),
    mergeMap((action) => {
      const tenant = new Tenant().deserialize(action.payload);
      return tenantAPI.create(tenant).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Cliente ${response.data.name} creado con éxito`, 
            "success",
            500
          );
          history.push({
            pathname: "/clientes/listado",
          });
          return createTenantFulfilled(response.data);
        }),
        catchError(error => of({type: TENANT_ERROR}))
      );
    })
  );

//  Action creator
export const updateTenant =
  ({ ...fields }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TENANT,
      payload: { ...fields },
    });
  };

//  Action creator
export function updateTenantFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TENANT_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateTenantEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_TENANT),
    mergeMap((action) => {
      const tenant = new Tenant().deserialize(action.payload);
      return tenantAPI.update(tenant).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            "Cliente editado con éxito",
            "success",
            500
          );
          history.push({
            pathname: "/clientes/listado",
          });
          return updateTenantFulfilled(response.data);
        })
      );
    })
  );
// export function createTenantF(data) {//{id, name, phone, logo, resume, bucket, domanin}) {
//   return dispatch => {
//     //
//   }
// };

//  Action creator
export const fetchTenants = (params = {}) => (dispatch) => {
  dispatch({
    type: FETCH_TENANT_DATA,
    params: params,
  });
};

//  Action creator
export function fetchTenantsFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: FETCH_TENANT_DATA_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const fetchTenantsEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_TENANT_DATA),
    mergeMap((action) => {
      return tenantAPI
        .getAll(action.params)
        .pipe(map((response) => fetchTenantsFulfilled(response.data)))
    }
    )
  );

//  Action creator
export const deleteTenant = (id) => (dispatch) => {
  dispatch({
    type: DELETE_TENANT,
    payload: id,
  });
};

//  Action creator
export function deleteTenantFulfilled(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_TENANT_FULFILLED,
      data: id,
    });
  };
}

// Epic

export const deleteTenantEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_TENANT),
    mergeMap((action) =>
      tenantAPI.delete(action.payload.id).pipe(
        map(() => {
          snackbarService.showSnackbar(
            "Se borró con éxito al usuario " + action.payload.name,
            "success",
            500
          );
          return deleteTenantFulfilled(action.payload.id);
        })
      )
    )
  );

//  Action creator
export const buildTenantSite = (tenant) => (dispatch) => {
  dispatch({
    type: BUILD_TENANT_SITE,
    payload: tenant,
  });
  snackbarService.showSnackbar(
    `Se inició el proceso de construcción del sitio web del usuario ${tenant.name}. Le notificaremos cuando el mismo finalice.`,
    "success",
    500
  );
};

//  Action creator
export function buildTenantSiteFulfilled(id) {
  return (dispatch) => {
    dispatch({
      type: BUILD_TENANT_SITE_FULFILLED,
      data: id,
    });
  };
}

// Epic

export const buildTenantSiteEpic = (action$) =>
  action$.pipe(
    ofType(BUILD_TENANT_SITE),
    mergeMap((action) =>
      tenantAPI.buildSite(action.payload.id).pipe(
        map(() => {
          snackbarService.showSnackbar(
            "Se completó la construcción del sitio web del usuario " +
              action.payload.name,
            "success",
            500
          );
          return buildTenantSiteFulfilled(action.payload.id);
        })
      )
    )
  );

export const tenantEpics = [
  createTenantEpic,
  updateTenantEpic,
  fetchTenantsEpic,
  deleteTenantEpic,
  buildTenantSiteEpic,
];

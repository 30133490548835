import Axios from "axios-observable";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import AuthUtils from "../services/authUtils";
import localStorageService from "../services/localStorageService";
import { environment } from "../environments/environment";

class GenericAxios {
  axiosInstance: Axios;

  constructor(protected apiUrl: string, protected endpoint: string) {
    this.axiosInstance = Axios.create({
      baseURL: `${this.apiUrl}/${this.endpoint}`,
      // headers: {
      //   "Content-Type": "application/json",
      // },
    });

    // Request interceptor for API calls
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        config.headers = {
          Authorization: `JWT ${localStorage.getItem("auth_token")}`,
          Accept: "application/json",
        };
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    // Response interceptor for API calls
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const errorResponse = error.response.data;
        const isTokenExpired = errorResponse.detail ? errorResponse.detail.includes("Token expired") : false;
        if (error.response.status === 403 && isTokenExpired) {
          // Refresh Token and set for next requests
          let tokenRefreshed = false;
          const user = localStorageService.getItem("auth_user");
          const url = `${environment.api.base}/${environment.api.refreshtoken}`
          const data = {email: user.email}
          Axios.post(url, data)
          .pipe(
            catchError((error) => {
              return throwError(error);
            }),
            map((res: any) => {
              return res.data;
            })
          ).subscribe(
            (response) => {
              const access_token = response.idToken;
              AuthUtils.setSession(access_token);
              Axios.defaults.headers.common["Authorization"] = "JWT " + access_token;
              tokenRefreshed = true;
            }
          );
          let timer = setInterval(() => {
            if (tokenRefreshed) {
              clearInterval(timer);
            }
            return Promise.reject(error);
          }, 1000);
        } else {
          return Promise.reject(error);
        }
      }
    );
  }
}

export default GenericAxios;

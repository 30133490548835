import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { Prepaid } from '../models/Prepaid';


class PrepaidAPI extends ResourceService<Prepaid> {

    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.osociales,
            'prepaid',
            Prepaid
        );
    }

}

export default new PrepaidAPI();

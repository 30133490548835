import { Resource } from "../core/Resource";

import { Template } from "./Template";

// const DEFAULT_TEMPLATE = 1;
export class Tenant extends Resource {
  public id!: number;
  public email!: string;
  public name!: string;
  public fantasy_name!: string;
  public phone_main?: string;
  public phone_second?: string;
  public phone_third?: string;
  public phone_fourth?: string;
  public mobile_phone?: string;
  public suizo_account?: string;
  public cuit?: string;
  public analytics?: string;
  public fiscal_data?: string;
  public logo?: File;
  public slogan?: string;
  public geo?: string;

  //Added
  //Location
  public pharmacies?: Array<
  {
    id: string, 
    name: string, 
    province:{id: string, name: string}, 
    city: {id: string, name: string}, 
    street_name: string,
    street_lvl: string
  }
  >
  //About Us
  public about_us?: 
  {
    id: number,
    description: string,
    slogan: string,
    store: File,
    team: File,
  }

  public template?: 
  { id: number,
    name: string,
    repository: string,
    description: string,
    live_preview: string,
    thumbnail: File,
    created_at: string,
  }

  public schedules: Array<
    {
      id: number,
      day: string,
      hours: string,
    }
  > = []
  public specialists: Array<
    {
      id: number;
      name: string;
      photo: File;
      position: string;
    }
  > = [];
  public services: number[] = [];
  public prepaids: number[] = [];
  // public backend_bucket?: string;
  // public storage_bucket?: string;
  public domain?: string;
  public fol_link?: string;
  public facebook?: string;
  public twitter?: string;
  public youtube?: string;
  public instagram?: string;
  public linkedin?: string;
  // public plan_id?: string;
  // public template_id?: string;
  public main_color?: string;
  public secondary_color?: string;
  
  //public services?: object;
  public promotions_list?: object;
  public news_list?: object;

  //public template?: Template | number;

  // constructor() {
  //   super();
  // }

  deserialize(input: any) {
    Object.assign(this, input);
    
    if (input.template) {          
      this.template = typeof(input.template) === 'number' ? input.template : new Template().deserialize(input.template);
    }

    if (input.analytics_id) {
      this.analytics = input.analytics_id;
    }
    
    return this;
  }

  serialize() {
    const formData = new FormData();
    const tenant = Object.assign(this as any, {});
    for (var key in tenant) {
      if (key === "logo") {
        if (tenant.logo) {
          formData.append("logo", tenant.logo, tenant.logo.name);
        }
      } else if (Array.isArray(tenant[key])) {
        // eslint-disable-next-line
        tenant[key].map((el: any) => {
          // eslint-disable-next-line
          Object.keys(el).map(key => {
            if (el[key] instanceof File) {
              formData.append(el['tempId'], el[key], el[key].name);
            }
          })
        });
        formData.append(key, JSON.stringify(tenant[key]));
      } else if (tenant[key] instanceof Object) {
        // eslint-disable-next-line 
        Object.keys(tenant[key]).map(k => {
          if (tenant[key][k] instanceof File) {
            formData.append(k, tenant[key][k], tenant[key][k].name);
          }
        })
        formData.append(key, JSON.stringify(tenant[key]));
      } else {
        if (key === "analytics") {
          if (tenant.analytics) {
            formData.append("analytics_id", tenant.analytics);
          }
        } else {
          formData.append(key, tenant[key]);
        }
      }
    }
    return formData;
  }
}

import {
  FETCH_ROLE_DATA,
  FETCH_ROLE_DATA_FULFILLED,
  DELETE_ROLE_FULFILLED,
  ROLE_ERROR,
  CREATE_ROLE,
  CREATE_ROLE_FULFILLED,
  UPDATE_ROLE,
  UPDATE_ROLE_FULFILLED,
} from "../actions/RoleActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const RoleReducer = function (state = initialState, action) {
  switch (action.type) {
    case ROLE_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case CREATE_ROLE: {
      return {
        ...state,
        saving: true,
      };
    }
    case CREATE_ROLE_FULFILLED: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.data],
      };
    }
    case UPDATE_ROLE: {
      return {
        ...state,
        saving: true,
      };
    }
    case UPDATE_ROLE_FULFILLED: {
      return {
        fetching: false,
        fetched: true,
        saving: false,
        data: [...state.data.map((el) => el.id === action.data.id ? action.data : el)],
      };
    }
    case FETCH_ROLE_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_ROLE_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case DELETE_ROLE_FULFILLED: {
      return {
        ...state,
        data: state.data.filter((row) => row.id !== action.data),
      };
    }
    default: {
      return state;
    }
  }
};

export default RoleReducer;

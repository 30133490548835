import { Resource } from '../core/Resource';

export class Service extends Resource {
  public id!: number;
  public title!: string;
  public description?: string;
  public icon?: File;
  // public created_at?: string;
  // public pharmacy_id?: string;

  // constructor() {
  //   super();
  // }

  deserialize(input: any) {
    // input.tenant = 0  // default to create
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const formData = new FormData();
    const service = Object.assign(this as any, {});
    for (var key in service) {
      if (key === "icon") {
        if (service.icon) {
          formData.append('icon', service.icon, service.icon.name)
        }
      } else {
        formData.append(key, service[key]);
      }
    }
    return formData;
  }

}

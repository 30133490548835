import React, { useEffect, useState } from 'react';
import styles from './index.module.css'

interface Props {
  fColumn: string,
  sColumn: string,
  scheduleData: Array<{day: string, from: string, to: string}>,
  onScheduleChange: (newSchedule: Data[]) => void, 
}

export interface Data {
  day: string,
  from: string,
  to: string,
  disabled?: boolean,
}

export default function TimeTable ({fColumn, sColumn, scheduleData, onScheduleChange}: Props) {

  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    if(!scheduleData)return;
    setData(scheduleData)
  }, [scheduleData])

  const toggleSelectedDay = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    const newData = data.map( row => row.day === e.target.name ? {...row, disabled: e.target.checked }: row)
    onScheduleChange(newData);
    setData(newData)
  }

  const setFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newData = data.map( row => row.day === e.target.name ? {...row, from: e.target.value }: row);
    onScheduleChange(newData);
    setData(newData)
  }

  const setTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newData = data.map( row => row.day === e.target.name ? {...row, to: e.target.value }: row) 
    onScheduleChange(newData)
    setData(newData)
  }

  return (
    <div>
      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <tr>
            <th> {fColumn} </th>
            <th> {sColumn} </th>
          </tr>
        </thead>
        <tbody>
        {data.length > 0 && 
          data.map( (data, idx) => 
            <tr key={idx} className={styles.tableItem}>  
              <td className={styles.dayInput}>
                <input 
                  type='checkbox'
                  name={data.day} 
                  onChange={toggleSelectedDay}
                  defaultChecked={data.disabled}
                />
                {data.day}
              </td>
              <td className={styles.timesInputCells}>
                <input 
                  className={styles.timeInputFrom}  
                  type="time"
                  alt={data.day + 'from'}
                  onChange={setFrom} 
                  name={data.day} 
                  disabled={!data.disabled}
                  defaultValue={data.from}  
                />
                
                <input 
                  className={styles.timeInputTo} 
                  type="time"
                  onChange={setTo} 
                  name={data.day} 
                  alt={data.day + 'to'}
                  disabled={!data.disabled}
                  defaultValue={data.to} 
                />
              </td> 
            </tr> 
          )
        } 
        </tbody>
      </table> 
    </div>
  );
};
import { Specialist } from "../../models/Specialist"
import SpecialistAPI from "../../apis/Specialists"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

export const SPECIALIST_ERROR = "SPECIALIST_ERROR"
export const CREATE_SPECIALIST = "CREATE_SPECIALIST";
export const CREATE_SPECIALIST_FULFILLED = "CREATE_SPECIALIST_FULFILLED";
export const FETCH_SPECIALIST_DATA = "SPECIALIST_FETCH_DATA";
export const FETCH_SPECIALIST_DATA_FULFILLED = "SPECIALIST_FETCH_DATA_FULFILLED";
export const DELETE_SPECIALIST = "DELETE_SPECIALIST"
export const DELETE_SPECIALIST_FULFILLED = "DELETE_SPECIALIST_FULFILLED"

//  Action creator
export const createSpecialist = ({name, title, degree, description, experience, training, workDays, specialities, tenant, photo}) => dispatch => {
  dispatch({
    type: CREATE_SPECIALIST,
    payload: {name, title, degree, description, experience, training, workDays, specialities, tenant, photo}
  });
}

//  Action creator
export function createSpecialistFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_SPECIALIST_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createSpecialistEpic = action$ => action$.pipe(
  ofType(CREATE_SPECIALIST),
  mergeMap(action => {
    const specialist = new Specialist().deserialize(action.payload);
    return SpecialistAPI.create(specialist)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Especialista ${response.data.name} creado con éxito`, 
            'success',
            500
          );
          history.push({
            pathname: "/especialistas/listado"
          });
          return createSpecialistFulfilled(response.data);
        }),
        catchError(error => of({type: SPECIALIST_ERROR}))
      )
    }
  )
);

//  Action creator
export const fetchSpecialists = () => dispatch => {
  dispatch({
    type: FETCH_SPECIALIST_DATA,
  });
}

//  Action creator
export function fetchSpecialistsFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_SPECIALIST_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchSpecialistsEpic = action$ => action$.pipe(
  ofType(FETCH_SPECIALIST_DATA),
  mergeMap(action =>
    SpecialistAPI.getAll()
      .pipe(
        map(response => fetchSpecialistsFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteSpecialist = (id) => dispatch => {
  dispatch({
    type: DELETE_SPECIALIST,
    payload: id
  });
}

//  Action creator
export function deleteSpecialistFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_SPECIALIST_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteSpecialistEpic = action$ => action$.pipe(
  ofType(DELETE_SPECIALIST),
  mergeMap(action =>
    SpecialistAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito especialista ' + action.payload.name,
            'success',
            500
          );
          return deleteSpecialistFulfilled(action.payload.id);
        })
      )
  )
);

export const specialistEpics = [
  createSpecialistEpic,
  fetchSpecialistsEpic,
  deleteSpecialistEpic
]


import { NotificationType } from "../../models/NotificationType"
import NotificationTypeAPI from "../../apis/NotificationsType"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { of } from "rxjs";

export const NOTIFICATION_TYPE_ERROR = "NOTIFICATION_TYPE_ERROR"
export const CREATE_NOTIFICATION_TYPE = "CREATE_NOTIFICATION_TYPE";
export const CREATE_NOTIFICATION_TYPE_FULFILLED = "CREATE_NOTIFICATION_TYPE_FULFILLED";
export const UPDATE_NOTIFICATION_TYPE = "UPDATE_NOTIFICATION_TYPE";
export const UPDATE_NOTIFICATION_TYPE_FULFILLED = "UPDATE_NOTIFICATION_TYPE_FULFILLED";
export const FETCH_NOTIFICATION_TYPE_DATA = "NOTIFICATION_TYPE_FETCH_DATA";
export const FETCH_NOTIFICATION_TYPE_DATA_FULFILLED = "NOTIFICATION_TYPE_FETCH_DATA_FULFILLED";
export const DELETE_NOTIFICATION_TYPE = "DELETE_NOTIFICATION_TYPE"
export const DELETE_NOTIFICATION_TYPE_FULFILLED = "DELETE_NOTIFICATION_TYPE_FULFILLED"

//  Action creator
export const createNotificationType = ({ id, name }) => dispatch => {
  dispatch({
    type: CREATE_NOTIFICATION_TYPE,
    payload: { id, name }
  });
}

//  Action creator
export function createNotificationTypeFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_NOTIFICATION_TYPE_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createNotificationTypeEpic = action$ => action$.pipe(
  ofType(CREATE_NOTIFICATION_TYPE),
  mergeMap(action => {
    const obj = new NotificationType().deserialize(action.payload);
    return NotificationTypeAPI.create(obj)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Noticia ${response.data.name} creada con éxito`,
            'success',
            500
          );
          history.push({
            pathname: "/novedades-tags/listado"
          });
          return createNotificationTypeFulfilled(response.data);
        }),
        catchError(error => of({ type: NOTIFICATION_TYPE_ERROR }))
      )
  }
  )
);

//  Action creator
export const updateNotificationType =
  ({ ...fields }) =>
    (dispatch) => {
      dispatch({
        type: UPDATE_NOTIFICATION_TYPE,
        payload: { ...fields },
      });
    };

//  Action creator
export function updateNotificationTypeFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NOTIFICATION_TYPE_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateNotificationTypeEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_NOTIFICATION_TYPE),
    mergeMap((action) => {
      const obj = new NotificationType().deserialize(action.payload);
      return NotificationTypeAPI.update(obj).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Tipo de notificacion ${obj.name} editada con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/notificaciones-tipos/listado",
          });
          return updateNotificationTypeFulfilled(response.data);
        })
      );
    })
  );

//  Action creator
export const fetchNotificationType = () => dispatch => {
  dispatch({
    type: FETCH_NOTIFICATION_TYPE_DATA,
  });
}

//  Action creator
export function fetchNotificationTypeFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_NOTIFICATION_TYPE_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchNotificationTypeEpic = action$ => action$.pipe(
  ofType(FETCH_NOTIFICATION_TYPE_DATA),
  mergeMap(action =>
    NotificationTypeAPI.getAll()
      .pipe(
        map(response => fetchNotificationTypeFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteNotificationType = (id) => dispatch => {
  dispatch({
    type: DELETE_NOTIFICATION_TYPE,
    payload: id
  });
}

//  Action creator
export function deleteNotificationTypeFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_NOTIFICATION_TYPE_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteNotificationTypeEpic = action$ => action$.pipe(
  ofType(DELETE_NOTIFICATION_TYPE),
  mergeMap(action =>
    NotificationTypeAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito el tipo de notificacion ' + action.payload.name,
            'success',
            500
          );
          return deleteNotificationTypeFulfilled(action.payload.id);
        })
      )
  )
);

export const notificationTypeEpics = [
  createNotificationTypeEpic,
  updateNotificationTypeEpic,
  fetchNotificationTypeEpic,
  deleteNotificationTypeEpic
]

import { Resource } from '../core/Resource';
import { NotificationType } from "./NotificationType";
import { Tenant } from "./Tenant";

export class Notification extends Resource {
  public id!: number;
  public created_at?: string;
  public title!: string;
  public subtitle!: string;
  public status?: string;
  public path?: string;
  public type?: NotificationType | number;
  public tenant?: Tenant | number;
  
  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return Object.assign(this as any, {});
  }

}

import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { retryWhen, catchError, map } from "rxjs/operators";
import { genericRetryStrategy } from "../core/genericRetryStrategy";
import { snackbarService } from "uno-material-ui";
import { throwError } from "rxjs";
import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { News } from '../models/News';


class NewsAPI extends ResourceService<News> {

    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.noticias,
            'news',
            News
        );
    }

    public assignNewsToTenant(data: {}): AxiosObservable<News> {
        return this.axiosInstance.post(`${this.url}/assign_news_to_tenant/`, data, { ...this.defaultHeader }).pipe(
          retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
          // timeout(10000),
          catchError((error) => {
            snackbarService.showSnackbar("Hubo un problema el intentar asignar noticias.", "error", 500);
            return throwError(error);
          }),
          map((res: any) => {
            // res.data = this.convertData(res.data);
            return res;
          })
        );
    }

    public assignTenantToNews(data: {}): AxiosObservable<News> {
      return this.axiosInstance.post(`${this.url}/assign_tenant_to_news/`, data, { ...this.defaultHeader }).pipe(
        retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
        // timeout(10000),
        catchError((error) => {
          snackbarService.showSnackbar("Hubo un problema el intentar asignar noticias.", "error", 500);
          return throwError(error);
        }),
        map((res: any) => {
          // res.data = this.convertData(res.data);
          return res;
        })
      );
  }
}

export default new NewsAPI();
import React from "react";
import { authRoles } from "../../auth/authRoles";

const templatesRoutes = [
  {
    path: "/templates/nuevo",
    component: React.lazy(() => import("./TemplatesForm")),
    auth: authRoles.admin
  }, 
  {
    path: "/templates/editar/:id",
    component: React.lazy(() => import("./TemplatesForm")),
    auth: authRoles.admin
  },
  {
    path: "/templates/listado",
    component: React.lazy(() => import("./Templates")),
    auth: authRoles.admin
  }
];

export default templatesRoutes;

import {
  FETCH_BANNER_DATA,
  FETCH_BANNER_DATA_FULFILLED,
  DELETE_BANNER_FULFILLED,
  BANNER_ERROR,
  CREATE_BANNER,
  CREATE_BANNER_FULFILLED,
  UPDATE_BANNER,
  UPDATE_BANNER_FULFILLED,
} from "../actions/BannerActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const BannerReducer = function (state = initialState, action) {
  switch (action.type) {
    case BANNER_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case CREATE_BANNER: {
      return {
        ...state,
        saving: true,
      };
    }
    case CREATE_BANNER_FULFILLED: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.data],
      };
    }
    case UPDATE_BANNER: {
      return {
        ...state,
        saving: true,
      };
    }
    case UPDATE_BANNER_FULFILLED: {
      if (state.data) {
        return {
          ...state,
          fetching: false,
          fetched: true,
          saving: false,
          data: [...state.data.map((el) => el.id === action.data.id ? action.data : el)],
        };
      } else {
        return {
          ...state,
          fetching: false,
          fetched: false,
          saving: false,
        };
      }
    }
    case FETCH_BANNER_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_BANNER_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case DELETE_BANNER_FULFILLED: {
      return {
        ...state,
        data: state.data.filter((row) => row.id !== action.data),
      };
    }
    default: {
      return state;
    }
  }
};

export default BannerReducer;

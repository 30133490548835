import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { Role } from '../models/Role';


class RoleAPI extends ResourceService<Role> {

    constructor(
    ) {
        super(environment.api.base, "roles", "role", Role);
    }
}

export default new RoleAPI();

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, Icon } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const ActionsMenu = (props) => {
  const { actions, id } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEvent = (action, id) => {
    action.method(id);
    handleClose();
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        aria-controls="customized-menu"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((action, index) => {
          return (
            <StyledMenuItem onClick={ action.method ? () => { handleEvent(action, id)} : handleClose} key={index}>
              <ListItemIcon>
                <Icon color={action.color ? action.color : 'inherit'}>{action.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={action.label} />
            </StyledMenuItem>
          )
        })}
      </StyledMenu>
    </div>
  );
}

export default ActionsMenu;

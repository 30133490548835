import React from "react";
import { authRoles } from "../../auth/authRoles";

const prepaidsRoutes = [
  {
    path: "/osociales/nuevo",
    component: React.lazy(() => import("./PrepaidForm")),
    auth: authRoles.admin
  },
  {
    path: "/osociales/editar/:id",
    component: React.lazy(() => import("./PrepaidForm")),
    auth: authRoles.admin
  },
  {
    path: "/osociales/listado",
    component: React.lazy(() => import("./Prepaids")),
    auth: authRoles.admin
  },
];

export default prepaidsRoutes;

import { Resource } from '../core/Resource';

export class Template extends Resource {
  public id!: number;
  public name!: string;
  public repository!: string;
  public description?: string;
  public live_preview!: string;
  public thumbnail?: File;
  public created_at!: string;

  // constructor() {
  //   super();
  // }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const formData = new FormData();
    const template = Object.assign(this as Template, {});
    for (const key in template) {
      if (key === 'thumbnail') {
        if (template.thumbnail) {
          formData.append('thumbnail', template.thumbnail, template.thumbnail.name)
        }
      } else {
        formData.append(key, template[key as keyof Template] as any);
      }
    }
    return formData;
  }

}

// import Axios from  'axios-observable';
import localStorageService from "./localStorageService";
import { snackbarService } from "uno-material-ui";
import UserAPI from "../apis/Users"
// import { addListInBoard } from '../redux/actions/ScrumBoardActions';

class AuthUtils {

    logout = () => {
        this.setSession(null);
        this.removeUser();
    }

    // Set token to all http request header, so you don't need to attach everytime
    setSession = (token: string | null) => {
        if (token) {
            localStorage.setItem("auth_token", token);
            // Axios.defaults.headers.common["Authorization"] = "JWT " + token;
            // Axios.interceptors.request.use(function (config) {
            //     config.headers.Authorization = "JWT " + token;
            //     return config;
            // });
        } else {
            localStorage.removeItem("auth_token");
            // delete Axios.defaults.headers.common["Authorization"];
        }
    };

    // Save user to localstorage
    setUser = (user: any) => {
        localStorageService.setItem("auth_user", user);
    }
    // Remove user from localstorage
    removeUser = () => {
        localStorage.removeItem("auth_user");
    }

    sendAuthDataToServer(user: any, extraParams: {}) {
        return new Promise(async (resolve, reject) => {
            const uid = user!.uid;
            const idToken = await user!.getIdToken();
            localStorage.setItem("auth_token", idToken);
            
            UserAPI.getByUID(uid, extraParams).subscribe(response => {
                return resolve(response.data);
            },
            error => {
                snackbarService.showSnackbar(
                    error,
                    'error',
                    500
                  );
                reject(error);
            });
        });
    }
}

export default new AuthUtils();
import React, { useState } from 'react';
import FilterTableData from './FilterTableData';
import styles from './index.module.css'

interface Props {
  data: Array<{id: string, name:string, type: string, isSelected: boolean}>,
  onDataSelected: (selected: Array<{id: string, name:string, type: string, isSelected: boolean}> ) => void,
}
  
export default function FilterTable ({ data, onDataSelected }: Props) {

  const [item, setItem] = useState('');
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItem(e.target.value)
  }


   // Search input matches with array
   const searchWord =  ( rows: Array<{id: string, name: string, type: string, isSelected: boolean}> ) => {
     return rows.filter( row => 
       row.name.toLowerCase().indexOf(item.toLowerCase()) > -1)
         .map(label =>label)
   }

  const handleElementSelected = (id: string) => {
    const selectedData = data.map(element => element.id !== id ? element : {...element, isSelected: !element.isSelected})
    onDataSelected(selectedData)
  }

  return (
    <div className={styles.container}>
      <form className={styles.item}>
        <div className={styles.entry}>
          <label className={styles.label}> Busqueda: </label>
          <input className={styles.input} value={item} placeholder='' name='date' onChange={onChangeHandler} />
        </div>
      </form>
      <FilterTableData data={searchWord(data)} onChange={handleElementSelected} />
    </div>
  );
};
import React from 'react';
import styles from './index.module.css';

interface Props {
  title: string;
};

export default function SectionTitle ({ title }: Props) {
  return (
    <div className={styles.container}>
      <span 
        className={styles.title}> 
        { title } 
      </span>
    </div>
  );
};

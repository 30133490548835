import { Resource } from '../core/Resource';
// import {Speciality} from "./Speciality";

export class Specialist extends Resource {
  public id!: number;
  public name!: string;
  public title!: string;
  public photo?: File;
  private tenant!: number;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const formData = new FormData();
    const specialist = Object.assign(this as any, {});
    for (var key in specialist) {
      if (key === "photo") {
        if (specialist.photo) {
          formData.append('photo', specialist.photo, specialist.photo.name)
        }
      } else if (key === "specialities") {
        formData.append(key, JSON.stringify(specialist[key]));
      } else {
        formData.append(key, specialist[key]);
      }
    }
    return formData;
  }

}

import { Resource } from '../core/Resource';

export class Banner extends Resource {
  public id!: number;
  public category!: string;
  public name!: string;
  public title!: string;
  public paragraph?: string;
  public link?: string;
  public image!: File;
  public image_mobile!: File;
  // public pharmacy_id?: string;

  // constructor() {
  //   super();
  // }

  deserialize(input: any) {
    // input.tenant = 0  // default to create
    Object.assign(this, input);
    return this;
  }

  serialize() {
      const formData = new FormData();
      const banner = Object.assign(this as any, {});
      for (var key in banner) {
        if (key === "image" || key === "image_mobile") {
          if (banner[key]) {
            formData.append(key, banner[key], banner[key].name)
          }
        } else {
          formData.append(key, banner[key]);
        }
      }
      return formData;
  }

}

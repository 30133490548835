import React from "react";
import { authRoles } from "../../auth/authRoles";

const servicesRoutes = [
  {
    path: "/servicios/nuevo",
    component: React.lazy(() => import("./ServiceForm")),
    auth: authRoles.admin
  },
  {
    path: "/servicios/editar/:id",
    component: React.lazy(() => import("./ServiceForm")),
    auth: authRoles.admin
  },
  {
    path: "/servicios/listado",
    component: React.lazy(() => import("./Services")),
    auth: authRoles.admin
  },
];

export default servicesRoutes;

import {
  FETCH_NEWS_TAG_DATA,
  FETCH_NEWS_TAG_DATA_FULFILLED,
  DELETE_NEWS_TAG_FULFILLED,
  NEWS_TAG_ERROR,
  CREATE_NEWS_TAG,
  CREATE_NEWS_TAG_FULFILLED,
  UPDATE_NEWS_TAG,
  UPDATE_NEWS_TAG_FULFILLED,
} from "../actions/NewsTagActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const NewsTagReducer = function (state = initialState, action) {
  switch (action.type) {
    case NEWS_TAG_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case CREATE_NEWS_TAG: {
      return {
        ...state,
        saving: true,
      };
    }
    case CREATE_NEWS_TAG_FULFILLED: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.data],
      };
    }
    case UPDATE_NEWS_TAG: {
      return {
        ...state,
        saving: true,
      };
    }
    case UPDATE_NEWS_TAG_FULFILLED: {
      return {
        fetching: false,
        fetched: true,
        saving: false,
        data: [...state.data.map((el) => el.id === action.data.id ? action.data : el)],
      };
    }
    case FETCH_NEWS_TAG_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_NEWS_TAG_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case DELETE_NEWS_TAG_FULFILLED: {
      return {
        ...state,
        data: state.data.filter((row) => row.id !== action.data),
      };
    }
    default: {
      return state;
    }
  }
};

export default NewsTagReducer;

/**
 * Returns a promise that converts an image File to base64
 * 
 * @param {File} image - A parameter type File
 * @returns {Promise<string>} Promise that resolves data into a base64 string. 
 */
function imgToBase64(image: File): Promise<string> {
  if(typeof(image) === 'string') return image;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });
}

export default imgToBase64;
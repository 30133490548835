import { Resource } from '../core/Resource';

export class NewsTag extends Resource {
  public id!: number;
  public name!: string;
  
  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const tag = Object.assign(this as any, {});
    return tag;
  }

}

import { NewsCategory } from "../../models/NewsCategory"
import NewsCategoryAPI from "../../apis/NewsCategories"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { of } from "rxjs";

export const NEWS_CATEGORY_ERROR = "NEWS_CATEGORY_ERROR"
export const CREATE_NEWS_CATEGORY = "CREATE_NEWS_CATEGORY";
export const CREATE_NEWS_CATEGORY_FULFILLED = "CREATE_NEWS_CATEGORY_FULFILLED";
export const UPDATE_NEWS_CATEGORY = "UPDATE_NEWS_CATEGORY";
export const UPDATE_NEWS_CATEGORY_FULFILLED = "UPDATE_NEWS_CATEGORY_FULFILLED";
export const FETCH_NEWS_CATEGORY_DATA = "NEWS_CATEGORY_FETCH_DATA";
export const FETCH_NEWS_CATEGORY_DATA_FULFILLED = "NEWS_CATEGORY_FETCH_DATA_FULFILLED";
export const DELETE_NEWS_CATEGORY = "DELETE_NEWS_CATEGORY"
export const DELETE_NEWS_CATEGORY_FULFILLED = "DELETE_NEWS_CATEGORY_FULFILLED"

//  Action creator
export const createNewsCategory = ({ id, name }) => dispatch => {
  dispatch({
    type: CREATE_NEWS_CATEGORY,
    payload: { id, name }
  });
}

//  Action creator
export function createNewsCategoryFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_NEWS_CATEGORY_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createNewsCategoryEpic = action$ => action$.pipe(
  ofType(CREATE_NEWS_CATEGORY),
  mergeMap(action => {
    const newsCategory = new NewsCategory().deserialize(action.payload);
    return NewsCategoryAPI.create(newsCategory)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Categoria ${response.data.name} creada con éxito`,
            'success',
            500
          );
          history.push({
            pathname: "/novedades-categorias/listado"
          });
          return createNewsCategoryFulfilled(response.data);
        }),
        catchError(error => of({ type: NEWS_CATEGORY_ERROR }))
      )
  }
  )
);

//  Action creator
export const updateNewsCategory =
  ({ ...fields }) =>
    (dispatch) => {
      dispatch({
        type: UPDATE_NEWS_CATEGORY,
        payload: { ...fields },
      });
    };

//  Action creator
export function updateNewsCategoryFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NEWS_CATEGORY_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateNewsCategoryEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_NEWS_CATEGORY),
    mergeMap((action) => {
      const newsCategory = new NewsCategory().deserialize(action.payload);
      return NewsCategoryAPI.update(newsCategory).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Categoria ${newsCategory.name} editada con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/novedades-categorias/listado",
          });
          return updateNewsCategoryFulfilled(response.data);
        })
      );
    })
  );

//  Action creator
export const fetchNewsCategory = () => dispatch => {
  dispatch({
    type: FETCH_NEWS_CATEGORY_DATA,
  });
}

//  Action creator
export function fetchNewsCategoryFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_NEWS_CATEGORY_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchNewsCategoryEpic = action$ => action$.pipe(
  ofType(FETCH_NEWS_CATEGORY_DATA),
  mergeMap(action =>
    NewsCategoryAPI.getAll()
      .pipe(
        map(response => fetchNewsCategoryFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteNewsCategory = (id) => dispatch => {
  dispatch({
    type: DELETE_NEWS_CATEGORY,
    payload: id
  });
}

//  Action creator
export function deleteNewsCategoryFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_NEWS_CATEGORY_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteNewsCategoryEpic = action$ => action$.pipe(
  ofType(DELETE_NEWS_CATEGORY),
  mergeMap(action =>
    NewsCategoryAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito la categoria ' + action.payload.name,
            'success',
            500
          );
          return deleteNewsCategoryFulfilled(action.payload.id);
        })
      )
  )
);

export const newsCategoryEpics = [
  createNewsCategoryEpic,
  updateNewsCategoryEpic,
  fetchNewsCategoryEpic,
  deleteNewsCategoryEpic
]

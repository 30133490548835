import {
  FETCH_PROMOTION_DATA,
  FETCH_PROMOTION_DATA_FULFILLED,
  DELETE_PROMOTION_FULFILLED,
  PROMOTION_ERROR,
  CREATE_PROMOTION,
  CREATE_PROMOTION_FULFILLED,
  UPDATE_PROMOTION,
  UPDATE_PROMOTION_FULFILLED,
} from "../actions/PromotionActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const PromotionReducer = function (state = initialState, action) {
  switch (action.type) {
    case PROMOTION_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case CREATE_PROMOTION: {
      return {
        ...state,
        saving: true,
      };
    }
    case CREATE_PROMOTION_FULFILLED: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.data],
      };
    }
    case UPDATE_PROMOTION: {
      return {
        ...state,
        saving: true,
      };
    }
    case UPDATE_PROMOTION_FULFILLED: {
      return {
        fetching: false,
        fetched: true,
        saving: false,
        data: [...state.data.map((el) => el.id === action.data.id ? action.data : el)],
      };
    }
    case FETCH_PROMOTION_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_PROMOTION_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case DELETE_PROMOTION_FULFILLED: {
      return {
        ...state,
        data: state.data.filter((row) => row.id !== action.data),
      };
    }
    default: {
      return state;
    }
  }
};

export default PromotionReducer;

import NewsletterAPI from "../../apis/Newsletter"
import { map, mergeMap } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';

export const NEWSLETTER_ERROR = "NEWSLETTER_ERROR"
export const FETCH_NEWSLETTER_DATA = "NEWSLETTER_FETCH_DATA";
export const FETCH_NEWSLETTER_DATA_FULFILLED = "NEWSLETTER_FETCH_DATA_FULFILLED";


//  Action creator
export const fetchNewsletter = () => dispatch => {
  dispatch({
    type: FETCH_NEWSLETTER_DATA,
  });
}

//  Action creator
export function fetchNewsletterFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_NEWSLETTER_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchNewsletterEpic = action$ => action$.pipe(
  ofType(FETCH_NEWSLETTER_DATA),
  mergeMap(action =>
    NewsletterAPI.getAll()
      .pipe(
        map(response => fetchNewsletterFulfilled(response.data))
      )
  )
);

export const newsletterEpics = [
  fetchNewsletterEpic
]
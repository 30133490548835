//Libraries + Modules + Hooks
import React from 'react';
import styles from './index.module.css';

//Components
import SectionTitle from '../../components/Section/SectionTitle';
import SectionSubtitle from '../../components/Section/SectionSubtitle';
import TemplateList from '../../components/TemplateList';
import WebColors from '../../components/WebColors';

interface Props {
  primaryColorSelected: (color: string) => void,
  secondaryColorSelected: (color: string) => void,
  primaryColor: string,
  secondaryColor: string,
  logo: File,
  templateIdSelected: number,
  templateSelected: Array<{id: number, preview: string, thumbnail: string}>,
}

export default function Confirm ({logo, primaryColor, secondaryColor, primaryColorSelected, secondaryColorSelected,templateIdSelected, templateSelected}: Props) {
    
  return(
      <>
        <div>
          <SectionTitle
            title= 'Verifica tu pedido'
          />
        </div>
        <div>
          <SectionSubtitle
            subtitle= 'Paleta de colores seleccionada:'
          />
        </div>
        <div className={styles.webColors}>
          <WebColors
            logo={logo}
            primaryColor={color => primaryColorSelected(color)}
            secondaryColor={color => secondaryColorSelected(color)}
            defaultPrimaryColor={primaryColor}
            defaultSecondaryColor={secondaryColor}
          />
        </div>
          <div>
          <SectionSubtitle
            subtitle= 'Tu template:'
          />
        </div>
        <div className={styles.templateSelected}>
          <TemplateList
            activeOption={templateIdSelected}
            onTemplateChange={ () => {} }
            options={templateSelected ? templateSelected : []}
          />
        </div>
      </>
  )
}
import ClientForm from "../../../forms/ClientForm";

  // const settings = {
  //   activeLayout: "layout1",
  //   layout1Settings: {
  //     topbar: {
  //       show: false
  //     },
  //     leftSidebar: {
  //       show: false,
  //       mode: "close"
  //     }
  //   },
  //   layout2Settings: {
  //     mode: "full",
  //     topbar: {
  //       show: false
  //     },
  //     navbar: { show: false }
  //   },
  //   secondarySidebar: { show: false },
  //   footer: { show: false }
  // };

  const clientFormRoutes = [
    {
      path: "/clientForm",
      component: ClientForm,
    },
  ];

export default clientFormRoutes;

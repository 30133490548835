import history from "../../../history.js";
// import jwtAuthService from "../../services/jwtAuthService";
import UserAPI from "../../apis/Users"
import { User } from "../../models/User";

import { snackbarService } from "uno-material-ui";
import { map, mergeMap } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';

export const USER_ERROR = "USER_ERROR"
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FULFILLED = "UPDATE_USER_FULFILLED";
export const FETCH_USER_DATA = "USER_FETCH_DATA";
export const FETCH_USER_DATA_FULFILLED = "USER_FETCH_DATA_FULFILLED";

//  Action creator
export const updateUser =
  ({ ...fields }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_USER,
      payload: { ...fields },
    });
  };

//  Action creator
export function updateUserFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateUserEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_USER),
    mergeMap((action) => {
      const user = new User().deserialize(action.payload);
      return UserAPI.update(user).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Usuario ${user.name} editado con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/usuarios/listado",
          });
          return updateUserFulfilled(response.data);
        })
      );
    })
  );


//  Action creator
export const fetchUsers = () => dispatch => {
  dispatch({
    type: FETCH_USER_DATA,
  });
}

//  Action creator
export function fetchUsersFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_USER_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchUsersEpic = action$ => action$.pipe(
  ofType(FETCH_USER_DATA),
  mergeMap(action =>
    UserAPI.getAll()
      .pipe(
        map(response => fetchUsersFulfilled(response.data))
      )
  )
);

export const usersEpics = [
  updateUserEpic, 
  fetchUsersEpic
]
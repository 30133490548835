import { Resource } from '../core/Resource';

export class Promotion extends Resource {
  public id!: number;
  public category!: string;
  public title!: string;
  public description?: string;
  public image!: File;
  // public pharmacy_id?: string;

  // constructor() {
  //   super();
  // }

  deserialize(input: any) {
    // input.tenant = 0  // default to create
    Object.assign(this, input);
    return this;
  }

  serialize() {
      const formData = new FormData();
      const promotion = Object.assign(this as any, {});
      for (var key in promotion) {
        if (key === "image") {
          if (promotion.image) {
            formData.append('image', promotion.image, promotion.image.name)
          }
        } else {
          formData.append(key, promotion[key]);
        }
      }
      return formData;
  }

}

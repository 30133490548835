import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { Schedule } from '../models/Schedule';


class ScheduleAPI extends ResourceService<Schedule> {
    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.horarios,
            'schedule',
            Schedule
        );
    }
}

export default new ScheduleAPI();

import { Deserializable } from './Deserializable';
import { Serializable } from './Serializable';

export abstract class Resource
  implements Deserializable<Resource>, Serializable {
  abstract id: any;

  abstract deserialize(input: any): Resource;

  abstract serialize(): {};
}

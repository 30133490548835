import React, { useRef, useState } from 'react';
import Button from '../Button';
import styles from './index.module.css'
import ProfileImg from './ProfileImg';

interface Props {
  fColumn: string,
  sColumn: string,
  tColumn: string,
  employees: Array<{photo: File, name: string, position: string}>
  employeesChange: (employees: Array<{photo: File, name: string, position: string}>) => void
}

interface StaffData {
  photo: File,
  name: string,
  position: string,
  disabled?: boolean,
}

export default function StaffTable ({fColumn, sColumn, tColumn, employeesChange, employees}: Props) {

  const fileInputRef = useRef<HTMLInputElement>(null)
  const [dataImage, setDataImage] = useState<File>()
  const [name, setName] = useState('')
  const [position, setPosition] = useState('')

  ///Data to Submit
  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (!files) return;
    setDataImage(files[0])
  }
  
  const addEmployee = () => {
    if( name && position ) {
      const newData = [...employees, {photo: dataImage!, name: name, position: position}]
      employeesChange(newData)
      setDataImage(undefined);
      setName('');
      setPosition('');
    }
  }

  const deleteRow = (employeeName: string, employeePhoto: File) => {
    const filteredData = employees.filter(employee => employee.name !== employeeName)
    employeesChange(filteredData)
  }

  return (
    <div>
      <table className={styles.table}>
        <thead className={styles.tableHead}>
          <tr className={styles.tableRow}>
            <th> {fColumn} </th>
            <th> {sColumn} </th>
            <th> {tColumn} </th>
            <th> Accion </th>
          </tr>
        </thead>
        <tbody className={styles.savedTableBody}>
          { employees && employees.map(
            (employee,idx) => 
              <tr key={idx} className={styles.tableRow}>
                <td>
                  <span>{employee.name}</span>
                </td>
                <td>
                  <span >{employee.position}</span>
                </td>
                <td className={styles.employeeImage}>
                  <input type='file' ref={fileInputRef} onChange={imageHandler} hidden />
                  <ProfileImg file={employee.photo} />
                 </td>
                <td className={styles.employeeAction}>
                <span onClick={() => deleteRow(employee.name, employee.photo)}> Eliminar </span>
                </td>
              </tr>
          )}
        </tbody>
        <tbody className={styles.tableBody}>
          <tr className={styles.tableRow}>  
            <td className={styles.cellName}>
              <input 
                className={styles.inputName}
                placeholder='Nombre y Apellido'   
                onChange={e => setName(e.target.value)} 
                value={name}
              />
            </td> 
            <td className={styles.cellPosition}>
              <input 
                className={styles.inputPosition} 
                placeholder='Posicion' 
                onChange={e => setPosition(e.target.value)}
                value={position}
              />
            </td>
            <td className={styles.cellImage}>
              <input 
                className={styles.inputImage}
                type='file'
                onChange={imageHandler}
              />
            </td>
          </tr> 
        </tbody>
      </table>
      <div className={styles.divButton}> 
        <Button children='Agregar' secondary={true} size='verySmall' onClick={addEmployee} />
      </div>
    </div>
  );
};
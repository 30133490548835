import React from 'react';
import styles from './index.module.css';
import TemplateOption from './TemplateOption';

interface Props {
  activeOption: number;
  options: Array<{id: number, preview: string, thumbnail: string}>;
  onTemplateChange: (id: number, thumbnail: string) => void
}

export default function TemplateList ({ activeOption, options, onTemplateChange}: Props) {  

  return (
    <div className={styles.optionListContainer}>
      {options.map( (option, idx) => {
        return (
          <div className={styles.optionContainer} key={idx}>
            <TemplateOption
              imageUrl={option.thumbnail}
              isSelected={ activeOption === idx +1 }
              onTemplate={() => onTemplateChange(idx+1 , option.thumbnail)}  
              onClick={ () => {} }
            />
          </div>
        )
      })}
    </div>
  );
};
//Libraries + Modules + Hooks
import React from 'react';

//Components
import Image from '../../components/Image';
import Input from '../../components/Input';
import SectionTitle from '../../components/Section/SectionTitle';
import StepComponentWrapper from '../../components/StepComponentWrapper';

interface Props {
  logoImageChange: (image: File) => void,
  businessNameChange: (value: string) => void,
  companyNameChange: (value: string) => void,
  cuitChange: (value: string) => void,
  analyticsChange: (value: string) => void,
  linkFolChange: (value: string) => void,
  webDomainChange: (value: string) => void,
  swissAccountNumberChange: (value: string) => void,
  businessCityChange: (value: string) => void,
  businessProvinceChange: (value: string) => void,
  businessStreetChange: (value: string) => void,
  businessStreetNumberChange: (value: string) => void,
  whatsAppChange: (value: string) => void,
  emailChange: (value: string) => void,
  taxDataChange: (value: string) => void,
  instagramChange: (value: string) => void,
  facebookChange: (value: string) => void,
  linkedinChange: (value: string) => void,
  twitterChange: (value: string) => void,
  youTubeChange:(value: string) => void,
  phoneNumber1Change: (value: string) => void,
  phoneNumber2Change: (value: string) => void,
  phoneNumber3Change: (value: string) => void,
  phoneNumber4Change: (value: string) => void,
  validateBusinessName: (value: string) => void,
  validateCompanyName: (value: string) => void,
  validateCuit: (value: string) => void,
  validateAnalytics: (value: string) => void,
  validateLinkFol: (value: string) => void,
  validateWebDomain: (value: string) => void,
  validateSwissAccountNumber: (value: string) => void,
  validateBusinessProvince: (value: string) => void,
  validateBusinessCity: (value: string) => void,
  validateBusinessStreet: (value: string) => void,
  validateBusinessStreetNumber: (value: string) => void,
  validateWhatsApp: (value: string) => void,
  validateEmail: (value: string) => void,
  validateTaxData: (value: string) => void,
  validateInstagram: (value: string) => void,
  validateFacebook: (value: string) => void,
  validateLinkedin: (value: string) => void,
  validateTwitter: (value: string) => void,
  validateYouTube: (value: string) => void,
  validatePhoneNumber1: (value: string) => void,
  validatePhoneNumber2: (value: string) => void,
  validatePhoneNumber3: (value: string) => void,
  validatePhoneNumber4: (value: string) => void,
  logoImage: File,
  businessName: string | undefined,
  businessNameError: string,
  companyName: string | undefined,
  companyNameError: string,
  cuit: string | undefined,
  analytics: string | undefined,
  cuitError: string,
  analyticsError: string,
  linkFol: string | undefined,
  linkFolError: string,
  webDomain: string | undefined,
  webDomainError: string,
  swissAccountNumber: string | undefined,
  swissAccountNumberError: string,
  businessProvince: string | undefined,
  businessProvinceError: string,
  businessCity: string | undefined,
  businessCityError: string,
  businessStreet: string | undefined,
  businessStreetError: string,
  businessStreetNumber: string | undefined, 
  businessStreetNumberError: string,
  whatsApp: string | undefined,
  whatsAppError: string,
  email: string | undefined,
  emailError: string,
  taxData: string | undefined,
  taxDataError: string,
  instagram: string | undefined,
  instagramError: string,
  facebook: string | undefined,
  facebookError: string,
  linkedin: string | undefined,
  linkedinError: string,
  twitter: string | undefined
  twitterError: string,
  youTube: string | undefined,
  youTubeError: string,
  phoneNumber1: string | undefined,
  phoneNumber1Error: string,
  phoneNumber2: string | undefined,
  phoneNumber2Error: string,
  phoneNumber3: string | undefined,
  phoneNumber3Error: string,
  phoneNumber4: string | undefined,
  phoneNumber4Error: string,
}

export default function YourData ({ 
  logoImageChange, 
  businessNameChange, 
  companyNameChange,
  cuitChange,
  analyticsChange,
  linkFolChange,
  webDomainChange,
  swissAccountNumberChange,
  businessCityChange,
  businessProvinceChange,
  businessStreetChange,
  businessStreetNumberChange,
  whatsAppChange,
  emailChange,
  taxDataChange,
  instagramChange,
  facebookChange,
  linkedinChange,
  twitterChange,
  youTubeChange,
  phoneNumber1Change,
  phoneNumber2Change,
  phoneNumber3Change,
  phoneNumber4Change,
  validateBusinessName,
  validateCompanyName,
  validateCuit,
  validateAnalytics,
  validateLinkFol,
  validateWebDomain,
  validateSwissAccountNumber,
  validateBusinessProvince,
  validateBusinessCity,
  validateBusinessStreet,
  validateBusinessStreetNumber,
  validateWhatsApp,
  validateEmail,
  validateTaxData,
  validateInstagram,
  validateFacebook,
  validateLinkedin,
  validateTwitter,
  validateYouTube,
  validatePhoneNumber1,
  validatePhoneNumber2,
  validatePhoneNumber3,
  validatePhoneNumber4,
  logoImage,
  businessName,
  companyName,
  cuit,
  analytics,
  linkFol,
  webDomain,
  swissAccountNumber,
  businessProvince,
  businessCity,
  businessStreet,
  businessStreetNumber,
  whatsApp,
  email,
  taxData,
  instagram,
  facebook,
  linkedin,
  twitter,
  youTube,
  phoneNumber1,
  phoneNumber2,
  phoneNumber3,
  phoneNumber4,
  businessNameError,
  companyNameError,
  cuitError,
  analyticsError,
  linkFolError,
  webDomainError,
  swissAccountNumberError,
  businessProvinceError,
  businessCityError,
  businessStreetError,
  businessStreetNumberError,
  whatsAppError,
  emailError,
  taxDataError,
  instagramError,
  facebookError,
  linkedinError,
  twitterError,
  youTubeError,
  phoneNumber1Error,
  phoneNumber2Error,
  phoneNumber3Error,
  phoneNumber4Error,
  }: Props) {

  return(
    <>
        <div>
          <SectionTitle
            title= 'Tus datos'
          />
        </div>
        <div>
          <StepComponentWrapper
            title='Su logo'
            stepList= {['Seleccione un logo para su pagina Web']}
            activeStep={1}
          >
            <Image
              label='Tu logo' 
              description='Arrastrá tu imagen acá o seleccioná un archivo. La imagen debe tener 500 x 200 pixels.' 
              onImageChange={ image => logoImageChange(image)} 
              image={logoImage}
              maxWidth={500}
              maxHeight={200}
            />
          </StepComponentWrapper>
        </div>
        <div>
          <Input 
            label='Nombre de Farmacia'
            placeholder='Nombre de Farmacia'
            required={true} 
            onChange={businessNameChange}
            value={businessName} 
            errorMessage={businessNameError}
            validator={validateBusinessName}
          />
        </div>
        <div>
          <Input 
            label='CUIT'
            placeholder='22388475522'
            required={true}
            onChange={value => cuitChange(value)}
            value={cuit}
            errorMessage={cuitError}
            validator={validateCuit}
          />
        </div>
        <div>
          <Input 
            label='ID de tracking (Google Analytics) si está disponible'
            placeholder='GA-22388475522'
            required={false}
            onChange={value => analyticsChange(value)}
            value={analytics}
            errorMessage={analyticsError}
            validator={validateAnalytics}
          />
        </div>
        <div>
          <Input 
            label='Razon Social'
            placeholder='Razon Social'
            required={false}
            onChange={value => companyNameChange(value)}
            value={companyName}
            errorMessage={companyNameError}
            validator={validateCompanyName}
          />
        </div>
        <div>
          <Input 
            label='Provincia'
            placeholder='Buenos Aires'
            required={true}
            onChange={value => businessProvinceChange(value)}
            value={businessProvince}
            errorMessage={businessProvinceError}
            validator={validateBusinessProvince}
          />
        </div>
        <div>
          <Input 
            label='Localidad'
            placeholder='Bahia Blanca'
            required={true}
            onChange={value => businessCityChange(value)}
            value={businessCity}
            errorMessage={businessCityError}
            validator={validateBusinessCity}
          />
        </div>
        <div>
          <Input 
            label='Calle'
            placeholder='Salta'
            required={true}
            onChange={value => businessStreetChange(value)}
            value={businessStreet}
            errorMessage={businessStreetError}
            validator={validateBusinessStreet}
          />
        </div>
        <div>
          <Input 
            label='Numero'
            placeholder='123'
            required={true}
            onChange={value => businessStreetNumberChange(value)}
            value={businessStreetNumber}
            errorMessage={businessStreetNumberError}
            validator={validateBusinessStreetNumber}
          />
        </div>
        <div>
          <Input 
            label='WhatsApp'
            placeholder='WhatsApp'
            required={true}
            onChange={value => whatsAppChange(value)}
            value={whatsApp}
            errorMessage={whatsAppError}
            validator={validateWhatsApp}
          />
        </div>
        <div>
          <Input 
            label='Email contacto'
            placeholder='username@mail.com'
            required={true}
            onChange={value => emailChange(value)}
            value={email}
            errorMessage={emailError}
            validator={validateEmail}
          />
        </div>
        <div>
          <Input 
            label='Link de FOL'
            placeholder='Link de FOL'
            required={true}
            onChange={value => linkFolChange(value)}
            value={linkFol}
            errorMessage={linkFolError}
            validator={validateLinkFol}
          />
        </div>
        <div>
          <Input 
            label='Dominio Web para el sitio'
            placeholder='https://sudominioweb.com/'
            required={true}
            onChange={value => webDomainChange(value)}
            value={webDomain}
            errorMessage={webDomainError}
            validator={validateWebDomain}
          />
        </div>
        <div>
          <Input 
            label='Nombre de Cuenta en Suizo'
            placeholder='Nombre de Cuenta en Suizo'
            required={true}
            onChange={value => swissAccountNumberChange(value)}
            value={swissAccountNumber}
            errorMessage={swissAccountNumberError}
            validator={validateSwissAccountNumber}
          />
        </div>
        <div>
          <Input 
            label='Data Fiscal'
            placeholder='Data Fiscal'
            required={true}
            onChange={value => taxDataChange(value)}
            value={taxData}
            errorMessage={taxDataError}
            validator={validateTaxData}
          />
        </div>
        <div>
          <SectionTitle
            title= 'Tus redes sociales'
          />
        </div>
        <div>
          <Input 
            label='Instagram'
            placeholder='Instagram'
            required={false}
            onChange={value => instagramChange(value)}
            value={instagram}
            errorMessage={instagramError}
            validator={validateInstagram}
          />
        </div>
        <div>
          <Input 
            label='Facebook'
            placeholder='Facebook'
            required={false}
            onChange={value => facebookChange(value)}
            value={facebook}
            errorMessage={facebookError}
            validator={validateFacebook}
          />
        </div>
        <div>
          <Input 
            label='Linkedin'
            placeholder='Linkedin'
            required={false}
            onChange={value => linkedinChange(value)}
            value={linkedin}
            errorMessage={linkedinError}
            validator={validateLinkedin}
          />
        </div>
        <div>
          <Input 
            label='Twitter'
            placeholder='Twitter'
            required={false}
            onChange={value => twitterChange(value)}
            value={twitter}
            errorMessage={twitterError}
            validator={validateTwitter}
          />
        </div>
        <div>
          <Input 
            label='YouTube'
            placeholder='YouTube'
            required={false}
            onChange={value => youTubeChange(value)}
            value={youTube}
            errorMessage={youTubeError}
            validator={validateYouTube}
          />
        </div>
        <div>
          <SectionTitle
            title= 'Contacto'
          />
        </div>
        <div>
          <Input 
            label='Telefono fijo 1'
            placeholder='Codigo de area + Telefono fijo'
            required={false}
            onChange={value => phoneNumber1Change(value)}
            value={phoneNumber1}
            errorMessage={phoneNumber1Error}
            validator={validatePhoneNumber1}
          />
        </div>
        <div>
          <Input 
            label='Telefono fijo 2'
            placeholder='Codigo de area + Telefono fijo'
            required={false}
            onChange={value => phoneNumber2Change(value)}
            value={phoneNumber2}
            errorMessage={phoneNumber2Error}
            validator={validatePhoneNumber2}
          />
        </div>
        <div>
          <Input 
            label='Telefono fijo 3'
            placeholder='Codigo de area + Telefono fijo'
            required={false}
            onChange={value => phoneNumber3Change(value)}
            value={phoneNumber3}
            errorMessage={phoneNumber3Error}
            validator={validatePhoneNumber3}
          />
        </div>
        <div>
          <Input 
            label='Telefono fijo 4'
            placeholder='Codigo de area + Telefono fijo'
            required={false}
            onChange={value => phoneNumber4Change(value)}
            value={phoneNumber4}
            errorMessage={phoneNumber4Error}
            validator={validatePhoneNumber4}
          />
        </div>
        </>
  )
}
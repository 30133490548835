import Axios from  'axios-observable';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  retryWhen,
  // timeout,
} from 'rxjs/internal/operators';
import errorHandler from './error/http-error-handler.service';
import { Resource } from './Resource';
import { genericRetryStrategy } from './genericRetryStrategy';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import GenericAxios from './GenericAxios';

export abstract class ResourceService<T extends Resource> {
  name = this.modelName;
  defaultHeader = {"headers": {"Authorization": "JWT " + localStorage.getItem("auth_token")}};
  protected url = `${this.apiUrl}/${this.endpoint}`;
  protected axiosInstance: Axios;
  constructor(
    // protected http: HttpClient,
    protected apiUrl: string,
    protected endpoint: string,
    protected modelName: string,
    protected TClass: { new (): T }
  ) {
    this.axiosInstance = new GenericAxios(apiUrl, endpoint).axiosInstance;
  }

  /**
   * Obtener un listado de elementos
   * @param params - Parámetros de búsqueda
   */
  public getAll(params: {} = {}): AxiosObservable<T> {
    return this.axiosInstance
    .get(this.url+'?format=json', { params, ...this.defaultHeader }).pipe(
      retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
      // timeout(10000),
      catchError(errorHandler.handleError),
      map((res: any) => {
        // res.data = this.convertData(res.data);
        return res;
      })
    );
  }

  /**
   * Obtener un elemento
   */
  public getOne(id: number): AxiosObservable<T> {
    return this.axiosInstance.get(`${this.url}/${id}/?format=json`, { ...this.defaultHeader })
    .pipe(
      retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
      // map((res: T) => new this.TClass().deserialize(res) as T),
      catchError(errorHandler.handleError)
    );
  }

  /**
   * Crear un elemento
   */
  public create(element: T): AxiosObservable<T> {
    const data = element.serialize();
    return this.axiosInstance.post(`${this.url}/`, data, { ...this.defaultHeader })
    .pipe(
      retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 409] })),
      // map((res: T) => new this.TClass().deserialize(res) as T),
      catchError(errorHandler.handleError)
    );
  }

  /**
   * Actualizar un elemento
   */
  public update(element: T): AxiosObservable<T> {
    const data = element.serialize();
    const id = element.id;
    return this.axiosInstance.patch(`${this.url}/${id}/`, data, { ...this.defaultHeader }).pipe(
      retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 409] })),
      // map((res: T) => new this.TClass().deserialize(res) as T),
      catchError(errorHandler.handleError)
    );
  }

  /**
   * Eliminar un elemento
   */
  public delete(id: number): Observable<any> {
    return this.axiosInstance
      .delete(`${this.url}/${id}/`, { ...this.defaultHeader })
      .pipe(catchError(errorHandler.handleError));
  }

  protected convertData(data: any[]): T[] {
    return data.map((item) => new this.TClass().deserialize(item) as T);
  }

}


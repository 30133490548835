import {
  FETCH_USER_DATA,
  FETCH_USER_DATA_FULFILLED,
  UPDATE_USER,
  UPDATE_USER_FULFILLED,
  USER_ERROR,
} from "../actions/UsersActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const usersReducer = function(state = initialState, action) {
  switch (action.type) {
    case USER_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        saving: true,
      };
    }
    case UPDATE_USER_FULFILLED: {
      return {
        fetching: false,
        fetched: true,
        saving: false,
        data: [...state.data.map((el) => el.id === action.data.id ? action.data : el)],
      };
    }
    case FETCH_USER_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_USER_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    default: {
      return state;
    }
  }
};

export default usersReducer;

import React from "react";
import { authRoles } from "../../auth/authRoles";

const bannersRoutes = [
  {
    path: "/banners/nuevo",
    component: React.lazy(() => import("./BannerForm")),
    auth: authRoles.admin
  }, 
  {
    path: "/banners/editar/:id",
    component: React.lazy(() => import("./BannerForm")),
    auth: authRoles.admin
  },
  {
    path: "/banners/listado",
    component: React.lazy(() => import("./Banners")),
    auth: authRoles.admin
  },
  {
    path: "/banners/asignar-banners/:id",
    component: React.lazy(() => import("./BannerAssignBanners")),
    auth: authRoles.admin
  },
  {
    path: "/banners/asignar-banners/",
    component: React.lazy(() => import("./BannerAssignBanners")),
    auth: authRoles.admin
  },
  {
    path: "/banners/asignar-clientes",
    component: React.lazy(() => import("./BannerAssignTenants")),
    auth: authRoles.admin
  },
];

export default bannersRoutes;

import { AxiosResponse } from "axios";
import { throwError, TimeoutError } from "rxjs";
import { snackbarService } from "uno-material-ui";

class HttpErrorHandlerService {
  // constructor() {}

  /**
   * En caso de ocurrir un httpError durante una llamda Http, se clasifica el mismo y se envía un mensaje 'user-friendly' al
   * componente que originó la llamada.
   * @param {HttpErrorResponse} httpError
   * @returns {Observable<never>}
   */
  public handleError(httpError: AxiosResponse | TimeoutError) {
    let message = "";

    if (httpError) {
      if (httpError instanceof TimeoutError) {
        message = "Tiempo de espera excedido. Reintentar.";
      } else {
        let { status } = httpError as AxiosResponse;

        if (httpError.request) {
          status = httpError.request.status;
          message = httpError.request.responseText;
          console.error(message);
        }

        if (message ) {
          message = formatMessage(message);
        } else {
          switch (status) {
            case 400: {
              message =
                "Error en la petición al Servidor. Verificar los campos.";
              break;
            }
            case 401: {
              message = "Error de autenticación. Cerrar y reiniciar la sesión.";
              break;
            }
            case 403: {
              message = "Sin permisos para completar la operación.";
              break;
            }
            case 404: {
              message = "No se encontró el recurso solicitado.";
              break;
            }
            case 422: {
              message = "Datos inválidos. Revisar los campos enviados.";
              break;
            }
            case 500: {
              message =
                "Se produjo un error en el servidor y la operación no pudo completarse.";
              break;
            }
            default: {
              message = "Algo no salió bien y la operación no se completó.";
            }
          }
        }
      }
    }
    snackbarService.showSnackbar(message, "error", 500);
    return throwError(message);
  }

}

const formatMessage = (message: string): string => {
  let formattedMessage = '';
  
  try {
    const arrayMessage = Object.entries(JSON.parse(message));    
    formattedMessage = `Ha${arrayMessage.length > 1 ? 'n' : ''} ocurrido ${arrayMessage.length} error${arrayMessage.length > 1 ? 'es' : ''} `;
    
    if (arrayMessage.length >= 1) {
      arrayMessage.map((el) => formattedMessage += `${el[0]}: ${el[1]}`);
    }
  } catch {
    formattedMessage = 'Ha ocurrido un error en el servidor';
  }

  return formattedMessage;
}

export default new HttpErrorHandlerService();

import {
  FETCH_SCHEDULE_DATA,
  FETCH_SCHEDULE_DATA_FULFILLED,
  DELETE_SCHEDULE_FULFILLED,
  SCHEDULE_ERROR,
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_FULFILLED
} from "../actions/ScheduleActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
};

const ScheduleReducer = function (state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case CREATE_SCHEDULE: {
      return {
        ...state,
        saving: true,
      };
    }
    case CREATE_SCHEDULE_FULFILLED: {
      return {
        ...state,
        data: action.data,
      };
    }
    case FETCH_SCHEDULE_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_SCHEDULE_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case DELETE_SCHEDULE_FULFILLED: {
      return {
        ...state,
        data: state.data.filter((row) => row.id !== action.data),
      };
    }
    default: {
      return state;
    }
  }
};

export default ScheduleReducer;

import localStorageService from "./services/localStorageService";
import { authRoles } from "./auth/authRoles";

export const getNavigations = () => {
  const user = localStorageService.getItem("auth_user");

  let menu = [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: "dashboard",
    },
  ];

  if (!user) return menu;

  if (authRoles.admin.includes(user.role)) {
    menu.push({
      name: "Clientes",
      icon: "people",
      children: [
        {
          name: "Listado",
          path: "/clientes/listado",
          iconText: "L",
        },
        {
          name: "Nuevo",
          path: "/clientes/nuevo",
          iconText: "N",
        },
      ],
    });
  }

  if (authRoles.tenant.includes(user.role)) {
    menu.push(
      {
        name: "Usuarios",
        icon: "portrait",
        children: [
          {
            name: "Listado",
            path: "/usuarios/listado",
            iconText: "L"
          },
        ]
      }
      
    );
  }

  if (authRoles.admin.includes(user.role)) {
    menu.push(
      {
        name: "Contactos",
        icon: "people_outline",
        children: [
          {
            name: "Listado",
            path: "/contactos/listado",
            iconText: "L"
          },
        ]
      }
      
    );
  }

  if (authRoles.admin.includes(user.role)) {
    menu.push(
      {
        name: "Servicios",
        icon: "work",
        children: [
          {
            name: "Listado",
            path: "/servicios/listado",
            iconText: "L"
          },
          {
            name: "Nuevo",
            path: "/servicios/nuevo",
            iconText: "N"
          }
        ]
      }      
    );
  }

  if (authRoles.admin.includes(user.role)) {
    menu.push(
      {
        name: "Obras Sociales",
        icon: "assignment_ind",
        children: [
          {
            name: "Listado",
            path: "/osociales/listado",
            iconText: "L"
          },
          {
            name: "Nuevo",
            path: "/osociales/nuevo",
            iconText: "NE"
          }
        ]
      }
    );
  }

  if (authRoles.admin.includes(user.role)) {
    menu.push(
      {
        name: "Promociones",
        icon: "monetization_on",
        children: [
          {
            name: "Listado",
            path: "/promociones/listado",
            iconText: "L"
          },
          {
            name: "Nuevo",
            path: "/promociones/nuevo",
            iconText: "N"
          },
          {
            name: "Asignar Promociones",
            path: "/promociones/asignar-promociones",
            iconText: "AP"
          },
          {
            name: "Asignar Clientes",
            path: "/promociones/asignar-clientes",
            iconText: "AC"
          }
        ]
      }
    );
  }

  if (authRoles.admin.includes(user.role)) {
    menu.push(
      {
        name: "Banners",
        icon: "burst_mode",
        children: [
          {
            name: "Listado",
            path: "/banners/listado",
            iconText: "L"
          },
          {
            name: "Nuevo",
            path: "/banners/nuevo",
            iconText: "N"
          },
          {
            name: "Asignar Banners",
            path: "/banners/asignar-banners",
            iconText: "AP"
          },
          {
            name: "Asignar Clientes",
            path: "/banners/asignar-clientes",
            iconText: "AC"
          }
        ]
      }
    );
  }

  if (authRoles.admin.includes(user.role)) {
    menu.push(
      {
        name: "Templates",
        icon: "photo_library",
        children: [
          {
            name: "Listado",
            path: "/templates/listado",
            iconText: "L"
          },
          {
            name: "Nuevo",
            path: "/templates/nuevo",
            iconText: "N"
          }
        ]
      }
      
    );
  }

  if (authRoles.admin.includes(user.role)) {
    menu.push(
      {
        name: "Novedades",
        icon: "library_books",
        children: [
          {
            name: "Listado",
            path: "/novedades/listado",
            iconText: "L"
          },
          {
            name: "Nuevo",
            path: "/novedades/nuevo",
            iconText: "N"
          },
          {
            name: "Categorías",
            iconText: "C",
    
              children: [
                {
                  name: "Listado Categorias",
                  path: "/novedades-categorias/listado",
                  iconText: "LC"
                },
                {
                  name: "Nueva Categoria",
                  path: "/novedades-categorias/nuevo",
                  iconText: "NC"
                },
              ]
          },
          {
            name: "Tags",
            iconText: "T",
    
              children: [
                {
                  name: "Listado Tags",
                  path: "/novedades-tags/listado",
                  iconText: "LT"
                },
                {
                  name: "Nuevo Tag",
                  path: "/novedades-tags/nuevo",
                  iconText: "NT"
                },
              ]
          },
          {
            name: "Asignar Novedades",
            path: "/novedades/asignar-novedades",
            iconText: "AN"
          },
          {
            name: "Asignar Clientes",
            path: "/novedades/asignar-clientes",
            iconText: "AC"
          }
        ]
      }      
    );
  }

  return menu;
};

export const navigations = getNavigations();

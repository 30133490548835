import React from "react";
import { authRoles } from "../../auth/authRoles";

const schedulesRoutes = [
  {
    path: "/horarios/nuevo",
    component: React.lazy(() => import("./ScheduleForm")),
    auth: authRoles.admin
  }, {
    path: "/horarios/listado",
    component: React.lazy(() => import("./Schedules")),
    auth: authRoles.admin
  }
];

export default schedulesRoutes;

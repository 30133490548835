import { Service } from "../../models/Service"
import serviceAPI from "../../apis/Services"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

export const SERVICE_ERROR = "SERVICE_ERROR"
export const CREATE_SERVICE = "CREATE_SERVICE";
export const CREATE_SERVICE_FULFILLED = "CREATE_SERVICE_FULFILLED";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_SERVICE_FULFILLED = "UPDATE_SERVICE_FULFILLED";
export const FETCH_SERVICE_DATA = "SERVICE_FETCH_DATA";
export const FETCH_SERVICE_DATA_FULFILLED = "SERVICE_FETCH_DATA_FULFILLED";
export const DELETE_SERVICE = "DELETE_SERVICE"
export const DELETE_SERVICE_FULFILLED = "DELETE_SERVICE_FULFILLED"

//  Action creator
export const createService = ({id, title, description, icon}) => dispatch => {
  dispatch({
    type: CREATE_SERVICE,
    payload: {id, title, description, icon}
  });
}

//  Action creator
export function createServiceFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_SERVICE_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createServiceEpic = action$ => action$.pipe(
  ofType(CREATE_SERVICE),
  mergeMap(action => {
    const service = new Service().deserialize(action.payload);
    return serviceAPI.create(service)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Servicio ${response.data.name} creado con éxito`, 
            'success',
            500
          );
          history.push({
            pathname: "/servicios/listado"
          });
          return createServiceFulfilled(response.data);
        }),
        catchError(error => of({type: SERVICE_ERROR}))
      )
    }
  )
);

//  Action creator
export const updateService =
  ({ ...fields }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE,
      payload: { ...fields },
    });
  };

//  Action creator
export function updateServiceFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SERVICE_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateServiceEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_SERVICE),
    mergeMap((action) => {
      const service = new Service().deserialize(action.payload);
      return serviceAPI.update(service).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            "Servicio editado con éxito",
            "success",
            500
          );
          history.push({
            pathname: "/servicios/listado",
          });
          return updateServiceFulfilled(response.data);
        })
      );
    })
  );

//  Action creator
export const fetchServices = () => dispatch => {
  dispatch({
    type: FETCH_SERVICE_DATA,
  });
}

//  Action creator
export function fetchServicesFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_SERVICE_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchServicesEpic = action$ => action$.pipe(
  ofType(FETCH_SERVICE_DATA),
  mergeMap(action =>
    serviceAPI.getAll()
      .pipe(
        map(response => fetchServicesFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteService = (id) => dispatch => {
  dispatch({
    type: DELETE_SERVICE,
    payload: id
  });
}

//  Action creator
export function deleteServiceFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_SERVICE_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteServiceEpic = action$ => action$.pipe(
  ofType(DELETE_SERVICE),
  mergeMap(action =>
    serviceAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito el servicio ' + action.payload.name, 
            'success',
            500
          );
          return deleteServiceFulfilled(action.payload.id);
        })
      )
  )
);

export const serviceEpics = [
  createServiceEpic,
  updateServiceEpic,
  fetchServicesEpic,
  deleteServiceEpic
]


import {
  FETCH_NOTIFICATION_TYPE_DATA,
  FETCH_NOTIFICATION_TYPE_DATA_FULFILLED,
  DELETE_NOTIFICATION_TYPE_FULFILLED,
  NOTIFICATION_TYPE_ERROR,
  CREATE_NOTIFICATION_TYPE,
  CREATE_NOTIFICATION_TYPE_FULFILLED,
  UPDATE_NOTIFICATION_TYPE,
  UPDATE_NOTIFICATION_TYPE_FULFILLED,
} from "../actions/NotificationTypeActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const NotificationTypeReducer = function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_TYPE_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case CREATE_NOTIFICATION_TYPE: {
      return {
        ...state,
        saving: true,
      };
    }
    case CREATE_NOTIFICATION_TYPE_FULFILLED: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.data],
      };
    }
    case UPDATE_NOTIFICATION_TYPE: {
      return {
        ...state,
        saving: true,
      };
    }
    case UPDATE_NOTIFICATION_TYPE_FULFILLED: {
      return {
        fetching: false,
        fetched: true,
        saving: false,
        data: [...state.data.map((el) => el.id === action.data.id ? action.data : el)],
      };
    }
    case FETCH_NOTIFICATION_TYPE_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_NOTIFICATION_TYPE_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case DELETE_NOTIFICATION_TYPE_FULFILLED: {
      return {
        ...state,
        data: state.data.filter((row) => row.id !== action.data),
      };
    }
    default: {
      return state;
    }
  }
};

export default NotificationTypeReducer;

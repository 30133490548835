import {
  FETCH_NOTIFICATION_DATA,
  FETCH_NOTIFICATION_DATA_FULFILLED,
  FETCH_NEW_NOTIFICATION_DATA,
  FETCH_NEW_NOTIFICATION_DATA_FULFILLED,
  DELETE_NOTIFICATION_FULFILLED,
  NOTIFICATION_ERROR,
  ADD_NOTIFICATION_STATE,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_FULFILLED,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_FULFILLED,
} from "../actions/NotificationActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const NotificationReducer = function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case ADD_NOTIFICATION_STATE: {
      return {
        ...state,
        data: [...state.data, action.data],
      };
    }
    case CREATE_NOTIFICATION: {
      return {
        ...state,
        saving: true,
      };
    }
    case CREATE_NOTIFICATION_FULFILLED: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.data],
      };
    }
    case UPDATE_NOTIFICATION: {
      return {
        ...state,
        saving: true,
      };
    }
    case UPDATE_NOTIFICATION_FULFILLED: {
      return {
        fetching: false,
        fetched: true,
        saving: false,
        data: [...state.data.filter((el) => el.id !== action.data.id)],
      };
    }
    case FETCH_NOTIFICATION_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_NOTIFICATION_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case FETCH_NEW_NOTIFICATION_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_NEW_NOTIFICATION_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    case DELETE_NOTIFICATION_FULFILLED: {
      return {
        ...state,
        data: state.data.filter((row) => row.id !== action.data),
      };
    }
    default: {
      return state;
    }
  }
};

export default NotificationReducer;

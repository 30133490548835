import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { Specialist } from '../models/Specialist';


class SpecialistAPI extends ResourceService<Specialist> {
    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.especialistas,
            'specialist',
            Specialist
        );
    }
}

export default new SpecialistAPI();

export const environment = {
  production: true,
  domain: "https://staging.farmawebs.com/",
  api: {
    base: "https://staging.farmawebs.com/api",
    baseWS: "wss://staging.farmawebs.com/api/ws",
    login: "auth/login",
    logout: "auth/logout",
    refresh: "auth/refresh",
    refreshtoken: "refreshtoken",
    clientes: "core/clientes",
    servicios: "core/servicios",
    osociales: "core/osociales",
    especialistas: "core/especialistas",
    promociones: "core/promociones",
    banners: "core/banners",
    especialidades: "core/especialidades",
    noticias: "core/noticias",
    noticiasCategorias: "core/noticias-categorias",
    noticiasTags: "core/noticias-tags",
    templates: "core/templates",
    farmacias: "core/farmacias",
    horarios: "core/horarios",
    newsletter: "core/newsletter",
    notificacionesTipos: "core/notificaciones-tipos",
    notificaciones: "core/notificaciones",
    notificacionesWS: "notificaciones",
  },
  googleApiKey: "AIzaSyDP6W-dYV_vEDiVIO_z5GvVVsRqLgQwOOY",
  gmapsApiKey: "AIzaSyCaZQmkoYdYLQzcLAUcIg2ak002aowQaEg",
  googleCloudStorage: "https://storage.cloud.google.com/",
  firebase: {
    apiKey: "AIzaSyDP6W-dYV_vEDiVIO_z5GvVVsRqLgQwOOY",
    authDomain: "farmawebs-com.firebaseapp.com",
    projectId: "farmawebs-com",
    storageBucket: "farmawebs-com.appspot.com",
    messagingSenderId: "251372029769",
    appId: "1:251372029769:web:624c9d0f1a297435c4fc98",
    measurementId: "G-1KZST7XF45",
  },
  build: "",
};

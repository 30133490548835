import { Resource } from '../core/Resource';

export class Speciality extends Resource {
  public id!: number;
  public name!: string;
  public description?: string;

  // constructor() {
  //   super();
  // }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const speciality = Object.assign(this as any, {});
    return speciality;
  }

}

import { Prepaid } from "../../models/Prepaid"
import prepaidAPI from "../../apis/Prepaids"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

export const PREPAID_ERROR = "PREPAID_ERROR"
export const CREATE_PREPAID = "CREATE_PREPAID";
export const CREATE_PREPAID_FULFILLED = "CREATE_PREPAID_FULFILLED";
export const UPDATE_PREPAID = "UPDATE_PREPAID";
export const UPDATE_PREPAID_FULFILLED = "UPDATE_PREPAID_FULFILLED";
export const FETCH_PREPAID_DATA = "PREPAID_FETCH_DATA";
export const FETCH_PREPAID_DATA_FULFILLED = "PREPAID_FETCH_DATA_FULFILLED";
export const DELETE_PREPAID = "DELETE_PREPAID"
export const DELETE_PREPAID_FULFILLED = "DELETE_PREPAID_FULFILLED"

//  Action creator
export const createPrepaid = ({id, type, name,}) => dispatch => {
  dispatch({
    type: CREATE_PREPAID,
    payload: {id, type, name,}
  });
}

//  Action creator
export function createPrepaidFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_PREPAID_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createPrepaidEpic = action$ => action$.pipe(
  ofType(CREATE_PREPAID),
  mergeMap(action => {
    const prepaid = new Prepaid().deserialize(action.payload);
    return prepaidAPI.create(prepaid)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Obra social ${response.data.name} creada con éxito`, 
            'success',
            500
          );
          history.push({
            pathname: "/osociales/listado"
          });
          return createPrepaidFulfilled(response.data);
        }),
        catchError(error => of({type: PREPAID_ERROR}))
      )
    }
  )
);

//  Action creator
export const updatePrepaid =
  ({ ...fields }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_PREPAID,
      payload: { ...fields },
    });
  };

//  Action creator
export function updatePrepaidFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PREPAID_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updatePrepaidEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_PREPAID),
    mergeMap((action) => {
      const prepaid = new Prepaid().deserialize(action.payload);
      return prepaidAPI.update(prepaid).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Obra social ${prepaid.name} editada con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/osociales/listado",
          });
          return updatePrepaidFulfilled(response.data);
        })
      );
    })
  );

//  Action creator
export const fetchPrepaids = () => dispatch => {
  dispatch({
    type: FETCH_PREPAID_DATA,
  });
}

//  Action creator
export function fetchPrepaidsFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_PREPAID_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchPrepaidsEpic = action$ => action$.pipe(
  ofType(FETCH_PREPAID_DATA),
  mergeMap(action =>
    prepaidAPI.getAll()
      .pipe(
        map(response => fetchPrepaidsFulfilled(response.data))
      )
  )
);


//  Action creator
export const deletePrepaid = (id) => dispatch => {
  dispatch({
    type: DELETE_PREPAID,
    payload: id
  });
}

//  Action creator
export function deletePrepaidFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_PREPAID_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deletePrepaidEpic = action$ => action$.pipe(
  ofType(DELETE_PREPAID),
  mergeMap(action =>
    prepaidAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito la obra social ' + action.payload.name, 
            'success',
            500
          );
          return deletePrepaidFulfilled(action.payload.id);
        })
      )
  )
);

export const prepaidEpics = [
  createPrepaidEpic,
  updatePrepaidEpic,
  fetchPrepaidsEpic,
  deletePrepaidEpic
]


import { Resource } from '../core/Resource';

export class News extends Resource {
  public id!: number;
  public created_at?: string;
  public date_published?: string;
  public slug?: string;
  public title!: string;
  public headline!: string;
  public content?: string;
  public category?: number;
  public province_name?: string; 
  public city_name?: string;
  public tags_list?: object;
  private image?: File;
  
  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    const formData = new FormData();
    const news = Object.assign(this as any, {});
    for (var key in news) {
      if (key === "image") {
        if (news.image) {
          formData.append('image', news.image, news.image.name)
        }
      } else if (Array.isArray(news[key])) {
        formData.append(key, JSON.stringify(news[key]));
      } else {
        formData.append(key, news[key]);
      }
    }
    return formData;
  }

}

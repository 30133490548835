import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  VERIFY_ID_TOKEN_FULFILLED
} from "../actions/UserActions";

const initialState = {};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }
    case VERIFY_ID_TOKEN_FULFILLED: {
      return {
        ...state,
        data: [...state.data, ...action.data],
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;

import React from 'react';
import styles from './index.module.css'

interface Props {
  data: Array<{id: string, name:string, type: string, isSelected: boolean}>
  onChange: (id: string, name: string, isSelected: boolean) => void;
}

export default function FilterTableData ({ data, onChange }: Props) {

  return(
    <div className={styles.tableContainer}>
      <div>
        <table className={styles.table}>
          <thead className={styles.tableItem}>
            <tr>
              <th> Elementos </th>
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
          {data.length > 0 ? 
            data.map( (data, idx) => 
              <tr key={idx} className={styles.tableItem}>  
                <td> <input type='checkbox' value={data.name} checked={data.isSelected} onChange={() => onChange(data.id, data.name, data.isSelected)} /> {data.name} </td>
              </tr> 
              ) : 
              <tr>
                <td className={styles.noData}> No hay coincidencias para esta busqueda </td>
              </tr> 
          } 
          </tbody>
        </table> 
      </div>
    </div> 
  );
};
export function validateEmail(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line  
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
} 

export function validateBusinessName(value: string | undefined): boolean {
  if( value === undefined) return false;
 // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[a-zA-ZÀ-ÿ0-9\u00f1\u00d1.,\s]+$/.test(value);
} 

export function validateCuit(value: string | undefined): boolean {
  if( value === undefined) return false;
 // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[\d]*$/.test(value) 
} 

export function validateAnalytics(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/.test(value);
} 

export function validateBusinessProvince(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/.test(value);
} 

export function validateBusinessCity(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/.test(value);
} 

export function validateBusinessStreet(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/.test(value);
} 

export function validateBusinessStreetNumber(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[\d]*$/.test(value)
} 
export function validateTaxData(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig.test(value)
} 

export function validateLinkFol(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig.test(value)
} 

export function validateCompanyName(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[a-zA-ZÀ-ÿ0-9\u00f1\u00d1.,\s]+$/.test(value);
} 

export function validateWebDomain(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0)
} 

export function validateSwissAccountNumber(value: string | undefined): boolean {
  if( value === undefined) return false;
  return (value !== null) && (value.length !== 0) && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig.test(value)
} 

export function validateWhatsApp(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[\d ]*$/.test(value)
} 

export function validateInstagram(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig.test(value)
} 

export function validateYouTube(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig.test(value)
} 

export function validateFacebook(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig.test(value)
} 

export function validateLinkedin(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig.test(value)
} 

export function validateTwitter(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig.test(value)
} 

export function validateSlogan(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[a-zA-ZÀ-ÿ\u00f1\u00d1.,\s]+$/.test(value);
} 

export function validateBusinessDescription(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[a-zA-ZÀ-ÿ\u00f1\u00d1.,:"\d\s]+$/.test(value);
} 

export function validatePhoneNumber1(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[\d ]*$/.test(value)
} 

export function validatePhoneNumber2(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[\d ]*$/.test(value)
} 

export function validatePhoneNumber3(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[\d ]*$/.test(value)
} 

export function validatePhoneNumber4(value: string | undefined): boolean {
  if( value === undefined) return false;
  // eslint-disable-next-line
  return (value !== null) && (value.length !== 0) && /^[\d ]*$/.test(value)
} 
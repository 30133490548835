import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { retryWhen, catchError, map } from "rxjs/operators";
import { ResourceService } from "../core/ResourceService";
import { genericRetryStrategy } from "../core/genericRetryStrategy";
import { environment } from "../environments/environment";
import { Tenant } from "../models/Tenant";
import { snackbarService } from "uno-material-ui";
import { throwError } from "rxjs";

class TenantAPI extends ResourceService<Tenant> {
  constructor() {
    super(environment.api.base, environment.api.clientes, "tenant", Tenant);
  }

  /**
   * Construir o actualizar site del cliente
   * @param params - Parámetros de construcción
   */
  public buildSite(id: number, params: {} = {}): AxiosObservable<Tenant> {
    return this.axiosInstance.post(`${this.url}/buildsite/${id}`, { params }, { ...this.defaultHeader }).pipe(
      retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
      // timeout(10000),
      catchError((error) => {
        snackbarService.showSnackbar("Hubo un problema el intentar inicar el proceso de construcción del sitio.", "error", 500);
        return throwError(error);
      }),
      map((res: any) => {
        // res.data = this.convertData(res.data);
        return res;
      })
    );
  }
}

export default new TenantAPI();

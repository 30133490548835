import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { retryWhen, catchError, map } from "rxjs/operators";
import { genericRetryStrategy } from "../core/genericRetryStrategy";
import { ResourceService } from "../core/ResourceService";
import { environment } from "../environments/environment";
import { User } from "../models/User";
import errorHandler from "../core/error/http-error-handler.service";

class UserAPI extends ResourceService<User> {
  constructor() {
    super(environment.api.base, "users", "user", User);
  }

  public getByUID(
    uid: string,
    extraParams: {} = {}
  ): AxiosObservable<User> {
    return this.axiosInstance.post(
      `${this.url}/get_by_uid/`,
      { uid, ...extraParams },
    ).pipe(
      retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
      catchError(errorHandler.handleError),
      map((res: any) => {
        return res;
      })
    );
  }

}

export default new UserAPI();

import { NewsTag } from "../../models/NewsTag"
import NewsTagAPI from "../../apis/NewsTags"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { of } from "rxjs";

export const NEWS_TAG_ERROR = "NEWS_TAG_ERROR"
export const CREATE_NEWS_TAG = "CREATE_NEWS_TAG";
export const CREATE_NEWS_TAG_FULFILLED = "CREATE_NEWS_TAG_FULFILLED";
export const UPDATE_NEWS_TAG = "UPDATE_NEWS_TAG";
export const UPDATE_NEWS_TAG_FULFILLED = "UPDATE_NEWS_TAG_FULFILLED";
export const FETCH_NEWS_TAG_DATA = "NEWS_TAG_FETCH_DATA";
export const FETCH_NEWS_TAG_DATA_FULFILLED = "NEWS_TAG_FETCH_DATA_FULFILLED";
export const DELETE_NEWS_TAG = "DELETE_NEWS_TAG"
export const DELETE_NEWS_TAG_FULFILLED = "DELETE_NEWS_TAG_FULFILLED"

//  Action creator
export const createNewsTag = ({ id, name }) => dispatch => {
  dispatch({
    type: CREATE_NEWS_TAG,
    payload: { id, name }
  });
}

//  Action creator
export function createNewsTagFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_NEWS_TAG_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createNewsTagEpic = action$ => action$.pipe(
  ofType(CREATE_NEWS_TAG),
  mergeMap(action => {
    const newsTag = new NewsTag().deserialize(action.payload);
    return NewsTagAPI.create(newsTag)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Noticia ${response.data.name} creada con éxito`,
            'success',
            500
          );
          history.push({
            pathname: "/novedades-tags/listado"
          });
          return createNewsTagFulfilled(response.data);
        }),
        catchError(error => of({ type: NEWS_TAG_ERROR }))
      )
  }
  )
);

//  Action creator
export const updateNewsTag =
  ({ ...fields }) =>
    (dispatch) => {
      dispatch({
        type: UPDATE_NEWS_TAG,
        payload: { ...fields },
      });
    };

//  Action creator
export function updateNewsTagFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NEWS_TAG_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateNewsTagEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_NEWS_TAG),
    mergeMap((action) => {
      const newsTag = new NewsTag().deserialize(action.payload);
      return NewsTagAPI.update(newsTag).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Noticia ${newsTag.name} editada con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/novedades-tags/listado",
          });
          return updateNewsTagFulfilled(response.data);
        })
      );
    })
  );

//  Action creator
export const fetchNewsTag = () => dispatch => {
  dispatch({
    type: FETCH_NEWS_TAG_DATA,
  });
}

//  Action creator
export function fetchNewsTagFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_NEWS_TAG_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchNewsTagEpic = action$ => action$.pipe(
  ofType(FETCH_NEWS_TAG_DATA),
  mergeMap(action =>
    NewsTagAPI.getAll()
      .pipe(
        map(response => fetchNewsTagFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteNewsTag = (id) => dispatch => {
  dispatch({
    type: DELETE_NEWS_TAG,
    payload: id
  });
}

//  Action creator
export function deleteNewsTagFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_NEWS_TAG_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteNewsTagEpic = action$ => action$.pipe(
  ofType(DELETE_NEWS_TAG),
  mergeMap(action =>
    NewsTagAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito la noticia ' + action.payload.name,
            'success',
            500
          );
          return deleteNewsTagFulfilled(action.payload.id);
        })
      )
  )
);

export const newsTagEpics = [
  createNewsTagEpic,
  updateNewsTagEpic,
  fetchNewsTagEpic,
  deleteNewsTagEpic
]

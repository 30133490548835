import {
  FETCH_NEWSLETTER_DATA,
  FETCH_NEWSLETTER_DATA_FULFILLED,
  NEWSLETTER_ERROR,
} from "../actions/NewsletterActions";

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  data: [],
};

const newsletterReducer = function(state = initialState, action) {
  switch (action.type) {
    case NEWSLETTER_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case FETCH_NEWSLETTER_DATA: {
      return {
        ...state,
        fetching: true,
      };
    }
    case FETCH_NEWSLETTER_DATA_FULFILLED: {
      return {
        ...state,
        data: [...action.data],
        fetching: false,
        fetched: true
      };
    }
    default: {
      return state;
    }
  }
};

export default newsletterReducer;

import { ResourceService } from "../core/ResourceService";
import { environment } from "../environments/environment";
import { Template } from "../models/Template";

class TemplateAPI extends ResourceService<Template> {
  constructor() {
    super(environment.api.base, environment.api.templates, "template", Template);
  }
}

export default new TemplateAPI();

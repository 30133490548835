import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { NewsTag } from '../models/NewsTag';


class NewsTagsAPI extends ResourceService<NewsTag> {

    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.noticiasTags,
            'newsTags',
            NewsTag
        );
    }
}

export default new NewsTagsAPI();

import { Role } from "../../models/Role"
import RoleAPI from "../../apis/Roles"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { of } from "rxjs";

export const ROLE_ERROR = "ROLE_ERROR"
export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_FULFILLED = "CREATE_ROLE_FULFILLED";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_FULFILLED = "UPDATE_ROLE_FULFILLED";
export const FETCH_ROLE_DATA = "ROLE_FETCH_DATA";
export const FETCH_ROLE_DATA_FULFILLED = "ROLE_FETCH_DATA_FULFILLED";
export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_FULFILLED = "DELETE_ROLE_FULFILLED"

//  Action creator
export const createRole = ({ id, template, role, title, content, image }) => dispatch => {
  dispatch({
    type: CREATE_ROLE,
    payload: { id, template, role, title, content, image }
  });
}

//  Action creator
export function createRoleFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_ROLE_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createRoleEpic = action$ => action$.pipe(
  ofType(CREATE_ROLE),
  mergeMap(action => {
    const role = new Role().deserialize(action.payload);
    return RoleAPI.create(role)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Rol creado con éxito`,
            'success',
            500
          );
          history.push({
            pathname: "/dashboard"
          });
          return createRoleFulfilled(response.data);
        }),
        catchError(error => of({ type: ROLE_ERROR }))
      )
  }
  )
);

//  Action creator
export const updateRole =
  ({ ...fields }) =>
    (dispatch) => {
      dispatch({
        type: UPDATE_ROLE,
        payload: { ...fields },
      });
    };

//  Action creator
export function updateRoleFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ROLE_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateRoleEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_ROLE),
    mergeMap((action) => {
      const role = new Role().deserialize(action.payload);
      return RoleAPI.update(role).pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Rol editado con éxito`,
            "success",
            500
          );
          history.push({
            pathname: "/dashboard",
          });
          return updateRoleFulfilled(response.data);
        })
      );
    })
  );

//  Action creator
export const fetchRoles = () => dispatch => {
  dispatch({
    type: FETCH_ROLE_DATA,
  });
}

//  Action creator
export function fetchRolesFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_ROLE_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchRolesEpic = action$ => action$.pipe(
  ofType(FETCH_ROLE_DATA),
  mergeMap(action =>
    RoleAPI.getAll()
      .pipe(
        map(response => fetchRolesFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteRole = (id) => dispatch => {
  dispatch({
    type: DELETE_ROLE,
    payload: id
  });
}

//  Action creator
export function deleteRoleFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_ROLE_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteRoleEpic = action$ => action$.pipe(
  ofType(DELETE_ROLE),
  mergeMap(action =>
    RoleAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito el Rol ' + action.payload.name,
            'success',
            500
          );
          return deleteRoleFulfilled(action.payload.id);
        })
      )
  )
);

export const roleEpics = [
  createRoleEpic,
  updateRoleEpic,
  fetchRolesEpic,
  deleteRoleEpic
]

import { Schedule } from "../../models/Schedule"
import ScheduleAPI from "../../apis/Schedules"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

export const SCHEDULE_ERROR = "SCHEDULE_ERROR"
export const CREATE_SCHEDULE = "CREATE_SCHEDULE";
export const CREATE_SCHEDULE_FULFILLED = "CREATE_SCHEDULE_FULFILLED";
export const FETCH_SCHEDULE_DATA = "SCHEDULE_FETCH_DATA";
export const FETCH_SCHEDULE_DATA_FULFILLED = "SCHEDULE_FETCH_DATA_FULFILLED";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE"
export const DELETE_SCHEDULE_FULFILLED = "DELETE_SCHEDULE_FULFILLED"

//  Action creator
export const createSchedule = ({name, description, pharmacy}) => dispatch => {
  dispatch({
    type: CREATE_SCHEDULE,
    payload: {name, description, pharmacy}
  });
}

//  Action creator
export function createScheduleFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_SCHEDULE_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createScheduleEpic = action$ => action$.pipe(
  ofType(CREATE_SCHEDULE),
  mergeMap(action => {
    const schedule = new Schedule().deserialize(action.payload);
    return ScheduleAPI.create(schedule)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            'Creando ..', 
            'success',
            500
          );
          history.push({
            pathname: "/horarios/listado"
          });
          return createScheduleFulfilled(response.data);
        }),
        catchError(error => of({type: SCHEDULE_ERROR}))
      )
    }
  )
);

//  Action creator
export const fetchSchedules = () => dispatch => {
  dispatch({
    type: FETCH_SCHEDULE_DATA,
  });
}

//  Action creator
export function fetchSchedulesFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_SCHEDULE_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchSchedulesEpic = action$ => action$.pipe(
  ofType(FETCH_SCHEDULE_DATA),
  mergeMap(action =>
    ScheduleAPI.getAll()
      .pipe(
        map(response => fetchSchedulesFulfilled(response.data))
      )
  )
);


//  Action creator
export const deleteSchedule = (id) => dispatch => {
  dispatch({
    type: DELETE_SCHEDULE,
    payload: id
  });
}

//  Action creator
export function deleteScheduleFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_SCHEDULE_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteScheduleEpic = action$ => action$.pipe(
  ofType(DELETE_SCHEDULE),
  mergeMap(action =>
    ScheduleAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito el horario ' + action.payload.name,
            'success',
            500
          );
          return deleteScheduleFulfilled(action.payload.id);
        })
      )
  )
);

export const scheduleEpics = [
  createScheduleEpic,
  fetchSchedulesEpic,
  deleteScheduleEpic
]


import { Resource } from '../core/Resource';

export class Pharmacy extends Resource {
  public id!: number;
  public name!: string;
  public province!: {id: string, name: string};
  public city!: {id: string, name: string};
  public street_name!: string;
  public street_lvl!: string;
  public coordinates?: object;
  
  // constructor() {
  //   super();
  // }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return Object.assign(this as any, {});
  }

}

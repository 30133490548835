import React from 'react';
import styles from './index.module.css';
import ProgressTrackerButton from './ProgressTrackerButton/index'

interface Props {
  selectedNod: number;
  labels: Array<string>;
}

export default function ProgressTracker({selectedNod, labels}: Props) {
  return (
    <div className={styles.progressTracker}>  
      {labels.map( ( label, idx) => 
        {  
          let status: 'idle' | 'done' | 'active' = 'idle';
          if (idx +1 < selectedNod) status = 'done';
          else if (idx +1 === selectedNod) status = 'active';
          
          return <ProgressTrackerButton
            key={idx} 
            index={ (status=== 'done') ? <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 21 21" width="14px" fill="#575AEF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg> :idx + 1}
            status={status}
            label={label}
          />
        }
      )}
    </div>
  );
}
import React from "react";
import { authRoles } from "../../auth/authRoles";

const specialistsRoutes = [
  {
    path: "/especialistas/nuevo",
    component: React.lazy(() => import("./SpecialistForm")),
    auth: authRoles.admin
  }, {
    path: "/especialistas/listado",
    component: React.lazy(() => import("./Specialists")),
    auth: authRoles.admin
  }
];

export default specialistsRoutes;

import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { retryWhen, catchError, map } from "rxjs/operators";
import { genericRetryStrategy } from "../core/genericRetryStrategy";
import { snackbarService } from "uno-material-ui";
import { throwError } from "rxjs";
import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { Banner } from '../models/Banner';


class BannerAPI extends ResourceService<Banner> {
    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.banners,
            'banner',
            Banner
        );
    }

    public assignBannersToTenant(data: {}): AxiosObservable<Banner> {
        return this.axiosInstance.post(`${this.url}/assign_banners_to_tenant/`, data, { ...this.defaultHeader }).pipe(
          retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
          // timeout(10000),
          catchError((error) => {
            snackbarService.showSnackbar("Hubo un problema al intentar asignar banners.", "error", 500);
            return throwError(error);
          }),
          map((res: any) => {
            // res.data = this.convertData(res.data);
            return res;
          })
        );
    }

    public assignTenantsToBanner(data: {}): AxiosObservable<Banner> {
      return this.axiosInstance.post(`${this.url}/assign_tenants_to_banner/`, data, { ...this.defaultHeader }).pipe(
        retryWhen(genericRetryStrategy({ excludedStatusCodes: [422, 403, 404] })),
        // timeout(10000),
        catchError((error) => {
          snackbarService.showSnackbar("Hubo un problema al intentar asignar banners.", "error", 500);
          return throwError(error);
        }),
        map((res: any) => {
          // res.data = this.convertData(res.data);
          return res;
        })
      );
  }
}

export default new BannerAPI();

import { ResourceService } from '../core/ResourceService';
import { environment } from '../environments/environment';
import { Notification } from '../models/Notification';


class NotificationAPI extends ResourceService<Notification> {

    constructor(
    ) {
        super(
            environment.api.base,
            environment.api.notificaciones,
            'notification',
            Notification
        );
    }

}

export default new NotificationAPI();
//Libraries + Modules + Hooks
import React from 'react';
import styles from './index.module.css';

import { Data } from '../../components/TimeTable'

//Components
import Image from '../../components/Image';
import SectionTitle from '../../components/Section/SectionTitle';
import SectionSubtitle from '../../components/Section/SectionSubtitle';
import Input from '../../components/Input';
import FilterTable from '../../components/FilterTable';
import StepComponentWrapper from '../../components/StepComponentWrapper';
import TimeTable from '../../components/TimeTable';
import StaffTable from '../../components/StaffTable';
import TextEditor from '../../components/TextEditor';

interface Props {
  businessImageChange: (image: File) => void,
  staffImageChange:  (value: File) => void,
  sloganChange: (value: string) => void,
  validateSlogan: (value: string) => void,
  businessDescriptionChange: (value: any) => void,
  scheduleData: Array<{day: string, from: string, to: string}>
  onScheduleChange: (newData: Data[]) => void,
  businessImage: File,
  staffImage: File,
  employees: Array<{photo: File, name: string, position: string}>,
  employeesChange: (employees: Array<{photo: File, name: string, position: string}>) => void,
  slogan: string | undefined,
  sloganError: string,
  businessDescription: any,
  dataServices: Array<{id: string, name:string, type: string, isSelected: boolean}>,
  dataPrepaids: Array<{id: string, name:string, type: string, isSelected: boolean}>,
  onServicesSelected: (selected: Array<{id: string, name: string, type: string, isSelected: boolean}>) => void,
  onPrepaidsSelected: (selected: Array<{id: string, name: string, type: string, isSelected: boolean}>) => void,
}

export default function AddInformation (
  { businessImageChange, 
    businessImage, 
    staffImageChange,
    staffImage, 
    employeesChange,
    employees,
    sloganChange, 
    businessDescriptionChange, 
    scheduleData,
    onScheduleChange,
    slogan, 
    sloganError,
    validateSlogan,
    businessDescription,
    dataServices, 
    dataPrepaids, 
    onServicesSelected,
    onPrepaidsSelected
  }: Props) {

  return(
      <>
        <div>
          <SectionTitle
            title= 'Agrega el contenido'
          />
        </div>
        <div className={styles.sectionSubtitle}>
          <SectionSubtitle
            subtitle= 'Home'
          />
        </div>
        <div>
          <StepComponentWrapper
            title='Su Farmacia'
            stepList= {['Seleccione una imagen de su farmacia para utilizar en su pagina Web']}
            activeStep={1}
          >
            <Image 
              label='Imagen del local' 
              description='Arrastrá tu imagen acá o seleccioná un archivo. La imagen debe tener 2000 x 800px.' 
              onImageChange={image => businessImageChange(image)}
              image={businessImage}
              maxWidth={2000}
              maxHeight={800}
            /> 
          </StepComponentWrapper>
        </div>
        <div>            
          <Input 
            label='Slogan'
            placeholder='Slogan'
            required={true} 
            onChange={value => sloganChange(value)}
            value={slogan}
            errorMessage={sloganError}
            validator={validateSlogan}
          />
        </div>
        <div>
          <label className={styles.descriptionLabel}>
           Descripcion
          </label>
          <div className={styles.textEditorContainer}>
          <TextEditor
            placeholder='Descripcion'
            content={businessDescription!}
            handleContentChange={value => businessDescriptionChange(value)}
          />
          </div>
        </div>
        <div className={styles.sectionSubtitle}>
          <SectionSubtitle
            subtitle= 'Nosotros'
          />
        </div>
        <div>
          <StepComponentWrapper
            title='Su Equipo'
            stepList= {['Seleccione una imagen del staff de la farmacia']}
            activeStep={1}
          >
            <Image 
              label='Imagen del equipo' 
              description='Arrastrá tu imagen acá o seleccioná un archivo. La imagen debe tener 2000 x 800px.' 
              onImageChange={ image => staffImageChange(image) }
              image={staffImage} 
              maxWidth={2000}
              maxHeight={800}
            /> 
          </StepComponentWrapper>
        </div>
        <div className={styles.sectionSubtitle}>
          <SectionSubtitle 
            subtitle='Horarios'
          />
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <TimeTable
            onScheduleChange={ newSchedule => onScheduleChange(newSchedule)}
            scheduleData={scheduleData}
            fColumn='Dia'
            sColumn='Horario'
          />
        </div>
        <div className={styles.sectionSubtitle}>
          <SectionSubtitle 
            subtitle='Nuestro Equipo'
          />
        </div>
        <div >
          <StaffTable
            fColumn='Nombre y Apellido'
            sColumn='Posicion'
            tColumn='Imagen'
            employees={employees}
            employeesChange={ employees => employeesChange(employees)}
          />
        </div>
        <div>
          <div className={styles.sectionSubtitle}>
            <SectionSubtitle 
              subtitle='Obras Sociales / Servicios'
            />
          </div>
        </div>
        <div>
          <StepComponentWrapper
            title='Obras Sociales / Servicios'
            stepList= {['Seleccione las obras sociales con las que trabaja su farmacia', 'Seleccione los servicios que se presentaran en su pagina web']}
            activeStep={1}
          >
          <div className={styles.filterTable}>
            <FilterTable
              data={dataServices}
              onDataSelected={selected => onServicesSelected(selected)}
            />
              <FilterTable
              data={dataPrepaids}
              onDataSelected={selected => onPrepaidsSelected(selected)}
            />
          </div>
          </StepComponentWrapper>
        </div>
      </>
  )
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import { getNavigationByUser } from "../redux/actions/NavigationAction";
// import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
import firebaseAuthService from "../services/firebase/firebaseAuthService";
import history from "../../history.js";
import AuthUtils from "../services/authUtils";

// const checkJwtAuth = async setUserData => {
//   // You need to send token to your server to check token is valid
//   // modify loginWithToken method in jwtService
//   let user = await jwtAuthService.loginWithToken();
//   if (user) {
//     setUserData(user);
//     AuthUtils.setUser(user); 
//   }
//   else
//     history.push({
//       pathname: "/session/signin"
//     });
//   return user;
// };

const checkFirebaseAuth = (setUserData) => {
  firebaseAuthService.checkAuthStatus((user) => {
    if (user) {
      firebaseAuthService.reauthenticate(user);
      const prevData = localStorageService.getItem("auth_user");

      if (!!prevData && !!user && prevData.uid === user.uid) {
        const userData = {
          ...user,
          token: prevData.token,
          userId: prevData.userId,
          role: prevData.role,
        };
        setUserData(userData);
        // AuthUtils.setUser(userData);

        return userData;
      }
      setUserData(user);
      AuthUtils.setUser(user);
      return user;
    } else {
      history.push({
        pathname: "/session/signin",
      });
    }
  });
};

const Auth = ({ children, setUserData, getNavigationByUser }) => {
  setUserData(localStorageService.getItem("auth_user"));

  useEffect(() => {
    checkFirebaseAuth(setUserData);
    getNavigationByUser();
  }, [setUserData, getNavigationByUser]);

  return <Fragment>{children}</Fragment>;
};

const mapStateToProps = (state) => ({
  setUserData: PropTypes.func.isRequired,
  getNavigationByUser: PropTypes.func.isRequired,
  login: state.login,
});

export default connect(mapStateToProps, { setUserData, getNavigationByUser })(
  Auth
);

import { Notification } from "../../models/Notification"
import NotificationAPI from "../../apis/Notifications"
import history from "../../../history.js";
import { snackbarService } from "uno-material-ui";

import { map, mergeMap, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { of } from "rxjs";

export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR"
export const ADD_NOTIFICATION_STATE = "ADD_NOTIFICATION_STATE";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_FULFILLED = "CREATE_NOTIFICATION_FULFILLED";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_FULFILLED = "UPDATE_NOTIFICATION_FULFILLED";
export const FETCH_NOTIFICATION_DATA = "NOTIFICATION_FETCH_DATA";
export const FETCH_NOTIFICATION_DATA_FULFILLED = "NOTIFICATION_FETCH_DATA_FULFILLED";
export const FETCH_NEW_NOTIFICATION_DATA = "NEW_NOTIFICATION_FETCH_DATA";
export const FETCH_NEW_NOTIFICATION_DATA_FULFILLED = "NEW_NOTIFICATION_FETCH_DATA_FULFILLED";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const DELETE_NOTIFICATION_FULFILLED = "DELETE_NOTIFICATION_FULFILLED"

//  Action add
export function addNotificationState(data) {
  return dispatch => {
    dispatch({
      type: ADD_NOTIFICATION_STATE,
      data: data
    });
  };
}

//  Action creator
export const createNotification = ({ id, name }) => dispatch => {
  dispatch({
    type: CREATE_NOTIFICATION,
    payload: { id, name }
  });
}

//  Action creator
export function createNotificationFulfilled(data) {
  return dispatch => {
    dispatch({
      type: CREATE_NOTIFICATION_FULFILLED,
      data: data
    });
  };
}

// Epic

export const createNotificationEpic = action$ => action$.pipe(
  ofType(CREATE_NOTIFICATION),
  mergeMap(action => {
    const obj = new Notification().deserialize(action.payload);
    return NotificationAPI.create(obj)
      .pipe(
        map((response) => {
          snackbarService.showSnackbar(
            `Noticia ${response.data.name} creada con éxito`,
            'success',
            500
          );
          history.push({
            pathname: "/novedades-tags/listado"
          });
          return createNotificationFulfilled(response.data);
        }),
        catchError(error => of({ type: NOTIFICATION_ERROR }))
      )
  }
  )
);

//  Action creator
export const updateNotification =
  ({ ...fields }) =>
    (dispatch) => {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { ...fields },
      });
    };

//  Action creator
export function updateNotificationFulfilled(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NOTIFICATION_FULFILLED,
      data: data,
    });
  };
}

// Epic

export const updateNotificationEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_NOTIFICATION),
    mergeMap((action) => {
      const obj = new Notification().deserialize(action.payload);
      return NotificationAPI.update(obj).pipe(
        map((response) => {
          return updateNotificationFulfilled(response.data);
        })
      );
    })
  );

//  Action creator
export const fetchNotification = () => dispatch => {
  dispatch({
    type: FETCH_NOTIFICATION_DATA,
  });
}

//  Action creator
export function fetchNotificationFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_NOTIFICATION_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchNotificationEpic = action$ => action$.pipe(
  ofType(FETCH_NOTIFICATION_DATA),
  mergeMap(action =>
    NotificationAPI.getAll()
      .pipe(
        map(response => fetchNotificationFulfilled(response.data))
      )
  )
);


//  Action creator
export const fetchNewNotification = () => dispatch => {
  dispatch({
    type: FETCH_NEW_NOTIFICATION_DATA,
  });
}

//  Action creator
export function fetchNewNotificationFulfilled(data) {
  return dispatch => {
    dispatch({
      type: FETCH_NEW_NOTIFICATION_DATA_FULFILLED,
      data: data
    });
  };
}

// Epic

export const fetchNewNotificationEpic = action$ => action$.pipe(
  ofType(FETCH_NEW_NOTIFICATION_DATA),
  mergeMap(action =>
    NotificationAPI.getAll({status: "NEW"})
      .pipe(
        map(response => fetchNewNotificationFulfilled(response.data))
      )
  )
);
//  Action creator
export const deleteNotification = (id) => dispatch => {
  dispatch({
    type: DELETE_NOTIFICATION,
    payload: id
  });
}

//  Action creator
export function deleteNotificationFulfilled(id) {
  return dispatch => {
    dispatch({
      type: DELETE_NOTIFICATION_FULFILLED,
      data: id
    });
  };
}

// Epic

export const deleteNotificationEpic = action$ => action$.pipe(
  ofType(DELETE_NOTIFICATION),
  mergeMap(action =>
    NotificationAPI.delete(action.payload.id)
      .pipe(
        map(() => {
          snackbarService.showSnackbar(
            'Se borró con éxito el tipo de notificacion ' + action.payload.name,
            'success',
            500
          );
          return deleteNotificationFulfilled(action.payload.id);
        })
      )
  )
);

export const notificationEpics = [
  createNotificationEpic,
  updateNotificationEpic,
  fetchNotificationEpic,
  fetchNewNotificationEpic,
  deleteNotificationEpic
]
